import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { IconButton, Slide } from '@material-ui/core';
import { Dialog } from '@mui/material';
import { useWindowWidth } from '@react-hook/window-size';
import React, { createRef, useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightIcon from '../assets/arrow-right.svg';
import KeyboardArrowLeftIcon from '../assets/arrow-left.svg';
import styles from './Carousel.module.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const BCCarousel = ({
  props,
  children,
  interval,
  autoPlay,
  onSlideChange,
  renderThumbs,
  selectedItem,
  renderIndicator,
  fullHD = true,
  thumbWidth = 80,
  showThumbs = false,
  infiniteLoop = true,
  showIndicators = true,
  disableFullscreen = false,
  customArrowPos = {},
  responsive,
  alwaysShowPrevious = false,
}) => {
  const [fullscreen, setFullscreen] = useState(false);
  const [fullscreenItemIndex, setFullscreenItemIndex] = useState(0);
  const [buttonStyles, setButtonStyles] = useState({
    position: 'absolute',
    zIndex: 2,
    buttonOffset: 20,
    borderRadius: '50%',
    textAlign: 'center',
    border: '1px solid #D4A976',
    backgroundColor: '#202020',
    top: 'calc(50% - 25px)',
    width: '50px',
    height: '50px',
    lineHeight: '50px',
    cursor: 'pointer',
  });
  useEffect(() => {
    if (Object.keys(customArrowPos).length) {
      setButtonStyles({
        ...customArrowPos,
        position: 'absolute',
        zIndex: 2,
        borderRadius: '50%',
        textAlign: 'center',
        border: '1px solid #D4A976',
        backgroundColor: '#202020',
        top: customArrowPos.top
          ? customArrowPos.top
          : customArrowPos.width
          ? `calc(50% - ${customArrowPos.width / 2}px)`
          : 'calc(50% - 25px)',
        width: customArrowPos.width || '50px',
        height: customArrowPos.height || '50px',
        lineHeight: '50px',
        cursor: 'pointer',
      });
    }
  }, [customArrowPos]);
  const [carouselHeight, setCarouselHeight] = useState(0);
  const width = useWindowWidth();
  const carousel = createRef();
  useEffect(() => {
    if (fullHD) {
      setCarouselHeight(carousel.current.offsetWidth / 1.78);
    }
  }, [width]);
  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child && child.props.children)) {
      return React.cloneElement(child.props.children, {
        priority: 'true',
        objectfit: 'contain',
        layout: 'fill',
      });
    }
    return child;
  });

  function toggleFullScreen(index) {
    setFullscreenItemIndex(index);
    setFullscreen(!fullscreen);
  }

  return (
    <>
      <Dialog
        open={fullscreen && !disableFullscreen}
        fullScreen
        onBackdropClick={toggleFullScreen}
        TransitionComponent={Transition}
        PaperProps={{ classes: { root: styles.dialogPaper } }}
      >
        <IconButton
          color="inherit"
          onClick={toggleFullScreen}
          aria-label="close"
          className={styles.closeDialog}
        >
          <CloseIcon />
        </IconButton>
        <Carousel
          selectedItem={fullscreenItemIndex}
          showThumbs={showThumbs}
          showStatus={false}
          showIndicators={children.length === 1 ? false : showIndicators}
          infiniteLoop={infiniteLoop}
          onChange={onSlideChange}
          preventMovementUntilSwipeScrollTolerance
          swipeScrollTolerance={100}
          autoPlay={autoPlay}
          interval={interval}
          thumbWidth={thumbWidth}
          renderThumbs={renderThumbs}
          renderIndicator={renderIndicator}
          className={styles.fullscreenCarousel}
          responsive={responsive}
          {...props}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            (alwaysShowPrevious || hasPrev) && (
              <button
                style={{ ...buttonStyles, left: buttonStyles.buttonOffset }}
                type="button"
                onClick={onClickHandler}
                title={label}
              >
                <KeyboardArrowLeftIcon
                  fontSize={'small'}
                  style={{ marginLeft: 17 }}
                />
              </button>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                style={{ ...buttonStyles, right: buttonStyles.buttonOffset }}
              >
                <KeyboardArrowRightIcon
                  fontSize={'small'}
                  style={{ marginLeft: 20 }}
                />
              </button>
            )
          }
        >
          {childrenWithProps}
        </Carousel>
      </Dialog>
      <div
        className={styles.carouselWrapper}
        style={{ height: carouselHeight || '100%', maxHeight: '100%' }}
        ref={carousel}
      >
        <Carousel
          selectedItem={selectedItem || null}
          showThumbs={showThumbs}
          showStatus={false}
          infiniteLoop={infiniteLoop}
          autoPlay={autoPlay}
          interval={interval}
          renderIndicator={renderIndicator}
          showIndicators={children.length === 1 ? false : showIndicators}
          onChange={onSlideChange}
          preventMovementUntilSwipeScrollTolerance
          swipeScrollTolerance={100}
          renderThumbs={renderThumbs}
          thumbWidth={thumbWidth}
          sx={{
            overflow: 'visible',
          }}
          onClickItem={(index) => toggleFullScreen(index)}
          {...props}
          responsive={responsive}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            (alwaysShowPrevious || hasPrev) && (
              <IconButton
                type="button"
                onClick={(evt) => {
                  if (alwaysShowPrevious && !hasPrev) {
                    evt.stopPropagation();
                    return;
                  } else {
                    onClickHandler();
                  }
                }}
                title={label}
                style={{
                  ...buttonStyles,
                  left: -buttonStyles.buttonOffset,
                  borderColor:
                    alwaysShowPrevious && !hasPrev ? 'grey' : '#D4A976',
                }}
                sx={{
                  path: {
                    stroke: alwaysShowPrevious && !hasPrev ? 'grey' : '#D4A976',
                  },
                }}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <IconButton
                type="button"
                onClick={onClickHandler}
                title={label}
                style={{ ...buttonStyles, right: -buttonStyles.buttonOffset }}
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            )
          }
        >
          {children}
        </Carousel>
      </div>
    </>
  );
};
