export const navigationItems = {
  airlines: [
    {
      label: 'Reviews',
      href: '/airlines/reviews',
    },
    {
      label: 'Articles',
      href: '/airlines/articles',
    },
    {
      label: 'TopLists',
      href: '/airlines/toplists',
    },
    {
      label: 'News',
      href: '/airlines/news',
    },
    {
      label: 'Offers',
      href: '/airlines/offers',
    },
  ],
  flights: [
    // {
    //   label: 'Deals Engine',
    //   href: '/deals',
    //   isShownForLoggedInUsers: true,
    // },
    {
      label: 'Business Class',
      href: '/flights/business-class',
    },
    {
      label: 'First Class',
      href: '/flights/first-class',
    },
    {
      label: 'Premium Economy Class',
      href: '/flights/premium-economy-class',
    },
    {
      label: 'Economy Class',
      href: '/flights',
    },
  ],
  hotels: [
    {
      label: 'Reviews',
      href: '/hotels/reviews',
    },
    {
      label: 'Articles',
      href: '/hotels/articles',
    },
    {
      label: 'TopLists',
      href: '/hotels/toplists',
    },
    {
      label: 'News',
      href: '/hotels/news',
    },
  ],
  more: [
    {
      label: 'About',
      href: '/about',
    },
    {
      label: 'Destinations',
      href: '/destinations',
      children: [
        // {
        //   label: 'Reviews',
        //   href: '/destinations/reviews/',
        // },
        {
          label: 'Articles',
          href: '/destinations/articles',
        },
        {
          label: 'TopLists',
          href: '/destinations/toplists',
        },
        {
          label: 'News',
          href: '/destinations/news',
        },
      ],
    },
    {
      label: 'Lifestyle',
      href: '/lifestyle',
      children: [
        // {
        //   label: 'Reviews',
        //   href: '/lifestyle/reviews/',
        // },
        {
          label: 'Articles',
          href: '/lifestyle/articles',
        },
        // {
        //   label: 'TopLists',
        //   href: '/lifestyle/toplists/',
        // },
        {
          label: 'News',
          href: '/lifestyle/news',
        },
      ],
    },
    {
      label: 'Guides',
      href: '/guides',
      children: [
        // {
        //   label: 'Reviews',
        //   href: '/guides/reviews',
        // },
        {
          label: 'Articles',
          href: '/guides/articles',
        },
        // {
        //   label: 'TopLists',
        //   href: '/guides/toplists',
        // },
        // {
        //   label: 'News',
        //   href: '/guides/news',
        // },
      ],
    },
    {
      label: 'Trains',
      href: '/trains',
      children: [
        {
          label: 'Reviews',
          href: '/trains/reviews',
        },
        // {
        //   label: 'Articles',
        //   href: '/trains/articles',
        // },
        {
          label: 'TopLists',
          href: '/trains/toplists',
        },
        {
          label: 'News',
          href: '/trains/news',
        },
      ],
    },
    // {
    //   label: 'Cars',
    //   href: '/cars',
    //   children: [
    //     {
    //       label: 'Reviews',
    //       href: '/cars/reviews',
    //     },
    //     {
    //       label: 'Articles',
    //       href: '/cars/articles',
    //     },
    //     {
    //       label: 'TopLists',
    //       href: '/cars/toplists',
    //     },
    //     {
    //       label: 'News',
    //       href: '/cars/news',
    //     },
    //   ],
    // },
    {
      label: 'Cruises',
      href: '/cruises',
      children: [
        // {
        //   label: 'Reviews',
        //   href: '/cruises/reviews',
        // },
        {
          label: 'Articles',
          href: '/cruises/articles',
        },
        // {
        //   label: 'TopLists',
        //   href: '/cruises/toplists',
        // },
        // {
        //   label: 'News',
        //   href: '/cruises/news',
        // },
      ],
    },
    {
      label: 'Airports',
      href: '/airports',
      children: [
        // {
        //   label: 'Reviews',
        //   href: '/airports/reviews',
        // },
        {
          label: 'Articles',
          href: '/airports/articles',
        },
        {
          label: 'TopLists',
          href: '/airports/toplists',
        },
        {
          label: 'News',
          href: '/airports/news',
        },
      ],
    },
    {
      label: 'Dining',
      href: '/dining',
      children: [
        {
          label: 'Reviews',
          href: '/dining/reviews',
        },
        {
          label: 'Articles',
          href: '/dining/articles',
        },
        {
          label: 'TopLists',
          href: '/dining/toplists',
        },
        {
          label: 'News',
          href: '/dining/news',
        },
      ],
    },
  ],
};
