export const CONTACT_INFO = {
  US: '+1 786 405 85 11',
  GB: '+44 203 455 03 90',
  AU: '+61 290 617 019',
  CA: '+1 647 956 0177',
  IN: '+911 171 279 371',
  NO: '+47 219 361 10',
  SE: '+46 8 525 099 29',
  DK: '+45 32 46 30 40',
};
