import { httpPut } from '../../utils/httpClient';

export function changeCountry(code, session) {
  httpPut(
    '/api/user/country',
    { country: code },
  ).then(() => {
    session();
  });
}
