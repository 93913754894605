import React, { useState } from 'react';
import Image from 'next/image';

export const ImageWithFallback = (props) => {
  const { ...rest } = props;
  const [fallbackState, setFallbackState] = useState(false);

  return fallbackState ? (
    <>
      <Image src="/images/bcbackground.webp" objectFit="cover" layout="fill" />
      <Image
        src="/logo_vertical.webp"
        objectFit="contain"
        layout="fill"
        className="p-4"
      />
    </>
  ) : (
    <Image
      {...rest}
      blurDataURL='/images/bcbackground.webp'
      onError={() => {
        setFallbackState(true);
      }}
    />
  );
};
