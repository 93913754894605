import styles from './ProgressMeter.module.scss';

export const ProgressMeter = ({ label, progress }) => {
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
  }
  return (
    <div className={styles.progressMeterContainer}>
      <div className={styles.progressMeterBar}>
        <div
          className={styles.progressMeterBarProgress}
          style={{ width: progress + '%' }}
        />
      </div>
      <div className={styles.progressMeterLabel}>
        {capitalizeFirstLetter(label)}
      </div>
    </div>
  );
};
