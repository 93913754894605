import styles from './BookNow.module.scss';
import { Button } from '@mui/material';
import cn from 'classnames';

export const BookNow = ({ hotelName, link }) => {
  return (
    <div className={styles.bookNowWrapper}>
      <div className={cn(styles.bookNowHotelName, 'font-garamond')}>
        {hotelName}
      </div>
      <Button
        color="primary"
        variant="contained"
        type="button"
        href={link}
        target={'_blank'}
        sx={{
          color: '#ffffff',
          background: '#7E183D',
          textTransform: 'unset',
          fontSize: '15px',
          lineHeight: '24px',
          width: '50%',
          margin: 'auto',
          '&:hover': {
            color: '#ffffff',
            background: '#7E183D',
          },
        }}
      >
        Book Now
      </Button>
    </div>
  );
};
