// pages/404.js
import cn from 'classnames';
import Head from 'next/head';
import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { httpGet } from '../../utils/httpClient';
import styles from './Lists.module.scss';
import { ReviewItem } from './ReviewItem';
import * as PropTypes from 'prop-types';
import HotelIcon from '@mui/icons-material/Hotel';
import { BCBreadcrumbs } from '../index';
import qs from 'qs';
import { Form, Formik } from 'formik';
import { HotelSearchAutocomplete } from '../HotelSearch/HotelSearchAutocomplete/HotelSearchAutocomplete';
import {
  createTheme,
  debounce,
  Dialog,
  TextField,
  ThemeProvider,
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@material-ui/core';
import SortIcon from '@mui/icons-material/Sort';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import CloseIcon from '@mui/icons-material/Close';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import { useWindowWidth } from '@react-hook/window-size';

const theme = createTheme({
  shape: {
    borderRadius: 12,
  },
  palette: {
    primary: {
      main: '#7E183D',
    },
    dark: {
      main: '#0a0a0a',
    },
  },
  typography: {
    fontFamily: '"Libre Franklin", sans-serif',
  },
});

const getReviews = async (
  listConfig,
  page,
  perPage,
  loadingHandler,
  filters = undefined
) => {
  let destUrl = `/api`;
  let q = {};
  let query = '';
  if (filters) {
    if (filters.name) {
      q.hotel = filters.name;
    }
    if (filters.autocomplete?.t === 'cities') {
      q.city = filters.autocomplete.n;
    } else if (filters.autocomplete?.t === 'countries') {
      q.country = filters.autocomplete.n;
    }
    query = qs.stringify(q);
    if (
      filters &&
      Object.keys(filters).length &&
      filters.sort &&
      Object.keys(filters.sort).length
    ) {
      if (filters.sort?.rating) {
        query += `&sort=hotel.score.total,${filters.sort?.rating}`;
      }
      if (filters.sort?.name) {
        query += `&sort=hotel.name,${filters.sort?.name}`;
      }
    }
  }
  switch (listConfig.type) {
    case 'destination':
      if (filters) {
        destUrl += `/destinations/hotels?country=${listConfig.country}&city=${listConfig.city}&page=${page}&size=${perPage}&${query}`;
      } else {
        destUrl += `/destinations/hotels?country=${listConfig.country}&city=${listConfig.city}&page=${page}&size=${perPage}`;
      }
      break;

    default:
      if (filters) {
        destUrl += `/hotel?page=${page}&size=${perPage}&${query}`;
      } else {
        destUrl += `/hotel?page=${page}&size=${perPage}`;
      }
      break;
  }

  loadingHandler(true);

  return await (await httpGet(decodeURI(destUrl))).json();
};

HotelReviewsList.propTypes = {
  type: PropTypes.string,
  country: PropTypes.string,
  city: PropTypes.string,
};

export function HotelReviewsList({ type, listTitle, country, city }) {
  const width = useWindowWidth();
  const [mobileFilters, setMobileFilters] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [reviews, setReviews] = useState([]);
  const defaultFilters = {
    name: '',
    autocomplete: '',
    sort: {
      name: null,
      rating: null,
    },
  };
  const [filters, setFilters] = useState(defaultFilters);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const listConfig = {
    type,
    country,
    city,
  };

  const perPage = 10;

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevFilters = usePrevious({ filters });

  useEffect(() => {
    if (
      JSON.stringify(defaultFilters) !== JSON.stringify(filters) &&
      JSON.stringify(prevFilters.filters) !== JSON.stringify(filters)
    ) {
      setCurrentPage(0);
      setLoading(true);
      setReviews([]);
      setHasMore(false);
      if (filters.sort?.name === null) {
        delete filters.sort.name;
      }
      if (filters.sort?.rating === null) {
        delete filters.sort.rating;
      }
      if (filters) {
        getReviews(listConfig, 0, perPage, setLoading, filters).then(
          (res) => {
            setHasMore(!res.last);
            setReviews([...res.content]);
            setLoading(false);
          }
        );
      }
      if (filters.resetFilters) {
        setFilters(defaultFilters);
        setCurrentPage(0);
      }
    }
  }, [filters]);
  useEffect(() => {
    getReviews(listConfig, currentPage, perPage, setLoading, filters).then(
      (res) => {
        setHasMore(!res.last);
        setReviews([...reviews, ...res.content]);
        setLoading(false);
      }
    );
  }, [currentPage]);
  const renderFilters = ({ setFieldValue, values, errors, touched }) => {
    return (
      <>
        {listConfig.type !== 'destination' && (
          <div className={cn(styles.filter)}>
            <HotelSearchAutocomplete
              setFieldValue={setFieldValue}
              name="autocomplete"
              placeholder="Enter destination"
              showOnly={['cities', 'countries']}
              icon={<LocationOnIcon />}
              error={!!(touched.autocomplete && errors.autocomplete)}
              primaryColor="#202020"
              value={values.autocomplete}
            />
          </div>
        )}
        <div className={styles.filter}>
          <TextField
            name="name"
            type="text"
            fullWidth
            value={values.name}
            placeholder="Hotel Name"
            primaryColor="#202020"
            onChange={(event) => {
              setFieldValue('name', event.target.value);
            }}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <Head>
        <link rel="canonical" href={`${process.env.apiUrl}/hotels/reviews`} />
      </Head>
      <div className={cn(styles.headingContainer, styles.hotel)}>
        <BCBreadcrumbs
          className="text-white"
          headerText={type && type === 'destination' ? listTitle : 'Reviews'}
        />
        <h1 className={cn(styles.heading, 'font-fragment text-center')}>
          <div className={styles.icon}>
            <HotelIcon className="m-auto text-white" />
          </div>
          {type && type === 'destination' ? listTitle : 'Hotel reviews'}
        </h1>
        {width && (
          <ThemeProvider theme={theme}>
            <Formik
              enableReinitialize
              initialValues={filters}
              validate={debounce((value) => {
                setFilters({ ...value });
              }, 1000)}
            >
              {({ setFieldValue, values, errors, touched, resetForm }) => (
                <Form>
                  <>
                    {width >= 1024 && (
                      <div className={styles.filtersContainer}>
                        {renderFilters({
                          setFieldValue,
                          values,
                          errors,
                          touched,
                        })}
                        <Tooltip title="Sort by rating">
                          <IconButton
                            color="inherit"
                            type="reset"
                            onClick={() => {
                              if (filters.sort?.rating === 'desc') {
                                setFieldValue('sort', {
                                  ...filters.sort,
                                  rating: 'asc',
                                });
                              }
                              if (filters.sort?.rating === 'asc') {
                                setFieldValue('sort', {
                                  ...filters.sort,
                                  rating: null,
                                });
                              }
                              if (!filters.sort?.rating) {
                                setFieldValue('sort', {
                                  ...filters.sort,
                                  rating: 'desc',
                                });
                              }
                            }}
                            aria-label="clear"
                            sx={{
                              border: '1px solid #fff',
                              borderRadius: '50%',
                              color: '#fff',
                              width: '56px',
                              marginRight: '1rem',
                            }}
                          >
                            <SortIcon />
                            {filters.sort?.rating === 'asc' && (
                              <ArrowRightAltIcon
                                sx={{
                                  position: 'absolute',
                                  right: '-2px',
                                  transform: 'rotate(90deg)',
                                }}
                              />
                            )}
                            {filters.sort?.rating === 'desc' && (
                              <ArrowRightAltIcon
                                sx={{
                                  position: 'absolute',
                                  right: '-2px',
                                  transform: 'rotate(-90deg)',
                                }}
                              />
                            )}
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Sort alphabetically">
                          <IconButton
                            color="inherit"
                            type="reset"
                            onClick={() => {
                              if (filters.sort?.name === 'desc') {
                                setFieldValue('sort', {
                                  ...filters.sort,
                                  name: 'asc',
                                });
                              }
                              if (filters.sort?.name === 'asc') {
                                setFieldValue('sort', {
                                  ...filters.sort,
                                  name: null,
                                });
                              }
                              if (!filters.sort?.name) {
                                setFieldValue('sort', {
                                  ...filters.sort,
                                  name: 'desc',
                                });
                              }
                            }}
                            aria-label="clear"
                            sx={{
                              border: '1px solid #fff',
                              borderRadius: '50%',
                              color: '#fff',
                              width: '56px',
                              marginRight: '1rem',
                            }}
                          >
                            <SortByAlphaIcon />
                            {filters.sort?.name === 'asc' && (
                              <ArrowRightAltIcon
                                sx={{
                                  position: 'absolute',
                                  right: '-2px',
                                  transform: 'rotate(90deg)',
                                }}
                              />
                            )}
                            {filters.sort?.name === 'desc' && (
                              <ArrowRightAltIcon
                                sx={{
                                  position: 'absolute',
                                  right: '-2px',
                                  transform: 'rotate(-90deg)',
                                }}
                              />
                            )}
                          </IconButton>
                        </Tooltip>
                        {JSON.stringify(defaultFilters) !==
                          JSON.stringify(filters) && (
                          <Tooltip title="Reset filters">
                            <IconButton
                              color="inherit"
                              type="reset"
                              onClick={() => {
                                resetForm({
                                  values: defaultFilters,
                                });
                                setFilters({ resetFilters: true });
                              }}
                              aria-label="clear"
                              sx={{
                                border: '1px solid #fff',
                                borderRadius: '50%',
                                color: '#fff',
                                width: '56px',
                                visibility: Object.keys(filters).length
                                  ? 'visible'
                                  : 'hidden',
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                    )}
                    {width < 1024 && (
                      <div className={styles.mobileFiltersWrapper}>
                        <div
                          className={styles.filtersButton}
                          onClick={() => setMobileFilters(true)}
                        >
                          <TuneRoundedIcon className={'mr-2'} /> Filters
                        </div>
                        <Dialog open={mobileFilters} fullWidth>
                          <div className={styles.filtersContainerMobile}>
                            <IconButton
                              color="inherit"
                              onClick={() => setMobileFilters(false)}
                              aria-label="close"
                              className={styles.closeMobileFilters}
                            >
                              <CloseIcon />
                            </IconButton>
                            {renderFilters({
                              setFieldValue,
                              values,
                              errors,
                              touched,
                            })}
                          </div>
                        </Dialog>
                        <div className={styles.sorting}>
                          <Tooltip title="Sort by rating">
                            <IconButton
                              color="inherit"
                              type="reset"
                              onClick={() => {
                                if (filters.sort?.rating === 'desc') {
                                  setFieldValue('sort', {
                                    ...filters.sort,
                                    rating: 'asc',
                                  });
                                }
                                if (filters.sort?.rating === 'asc') {
                                  setFieldValue('sort', {
                                    ...filters.sort,
                                    rating: null,
                                  });
                                }
                                if (!filters.sort?.rating) {
                                  setFieldValue('sort', {
                                    ...filters.sort,
                                    rating: 'desc',
                                  });
                                }
                              }}
                              aria-label="clear"
                              sx={{
                                border: '1px solid #fff',
                                borderRadius: '50%',
                                color: '#fff',
                                width: '42px',
                                marginRight: '1.25rem',
                              }}
                            >
                              <SortIcon />
                              {filters.sort?.rating === 'asc' && (
                                <ArrowRightAltIcon
                                  sx={{
                                    position: 'absolute',
                                    right: '-21px',
                                    transform: 'rotate(90deg)',
                                  }}
                                />
                              )}
                              {filters.sort?.rating === 'desc' && (
                                <ArrowRightAltIcon
                                  sx={{
                                    position: 'absolute',
                                    right: '-21px',
                                    transform: 'rotate(-90deg)',
                                  }}
                                />
                              )}
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Sort alphabetically">
                            <IconButton
                              color="inherit"
                              type="reset"
                              onClick={() => {
                                if (filters.sort?.name === 'desc') {
                                  setFieldValue('sort', {
                                    ...filters.sort,
                                    name: 'asc',
                                  });
                                }
                                if (filters.sort?.name === 'asc') {
                                  setFieldValue('sort', {
                                    ...filters.sort,
                                    name: null,
                                  });
                                }
                                if (!filters.sort?.name) {
                                  setFieldValue('sort', {
                                    ...filters.sort,
                                    name: 'desc',
                                  });
                                }
                              }}
                              aria-label="clear"
                              sx={{
                                border: '1px solid #fff',
                                borderRadius: '50%',
                                color: '#fff',
                                width: '42px',
                                marginRight: '1.25rem',
                              }}
                            >
                              <SortByAlphaIcon />
                              {filters.sort?.name === 'asc' && (
                                <ArrowRightAltIcon
                                  sx={{
                                    position: 'absolute',
                                    right: '-21px',
                                    transform: 'rotate(90deg)',
                                  }}
                                />
                              )}
                              {filters.sort?.name === 'desc' && (
                                <ArrowRightAltIcon
                                  sx={{
                                    position: 'absolute',
                                    right: '-21px',
                                    transform: 'rotate(-90deg)',
                                  }}
                                />
                              )}
                            </IconButton>
                          </Tooltip>
                          {JSON.stringify(defaultFilters) !==
                            JSON.stringify(filters) && (
                            <Tooltip title="Reset filters">
                              <IconButton
                                color="inherit"
                                type="reset"
                                onClick={() => {
                                  resetForm({
                                    values: defaultFilters,
                                  });
                                  setFilters({ resetFilters: true });
                                }}
                                aria-label="clear"
                                sx={{
                                  border: '1px solid #fff',
                                  borderRadius: '50%',
                                  color: '#fff',
                                  width: '42px',
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                </Form>
              )}
            </Formik>
          </ThemeProvider>
        )}
      </div>
      <div className={cn(styles.listContainer, 'container')}>
        <InfiniteScroll
          dataLength={reviews.length} //This is important field to render the next data
          next={() => setCurrentPage(currentPage + 1)}
          hasMore={hasMore}
        >
          <div className={styles.list}>
            {reviews.map((review, index) => {
              return (
                <div key={index} className={styles.review}>
                  <ReviewItem
                    type="Hotel"
                    starRating={true}
                    review={review}
                    url={`/hotel/review/${review.textId}`}
                  />
                </div>
              );
            })}
          </div>
        </InfiniteScroll>
        {loading && <div className={styles.loader} />}
        {!reviews.length && !loading && (
          <div className={styles.noReviewsFound}>No reviews found...</div>
        )}
      </div>
    </>
  );
}
