import React from 'react';

export const DropDownItem = ({ item, ...props }) => {

  return (
    <div {...props} >
      {item.label}
    </div>
  );
};
