import {
  Autocomplete,
  CircularProgress,
  createTheme,
  Paper,
  TextField,
  ThemeProvider,
} from '@mui/material';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { httpGet } from '../../../utils/httpClient';
import styles from './HotelSearchAutocomplete.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setAutocompleteCache } from '../../../utils/store/features/hotelAutocompleteCacheSlice';

export const HotelSearchAutocomplete = ({
  label,
  placeholder,
  icon,
  name,
  setFieldValue,
  showOnly,
  error,
  value,
  primaryColor = '#00454A',
}) => {
  const theme = createTheme({
    shape: {
      borderRadius: 12,
    },
    palette: {
      primary: {
        main: primaryColor,
      },
    },
    typography: {
      fontFamily: '"Libre Franklin", sans-serif',
    },
  });
  const [hotelCombinedOptions, setHotelCombinedOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const hotelAutocompleteCacheStore = useSelector(
    (state) => state.hotelAutocompleteCache?.value
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (value) {
      setOpen(false);
    }
  }, [value]);
  const setInputValue = (val) => {
    setLoading(true);
    if (hotelAutocompleteCacheStore[val]) {
      setHotelCombinedOptions(hotelAutocompleteCacheStore[val]);
      setLoading(false);
    } else {
      httpGet(`/api/hotel/autocomplete?search=${val}`).then((response) => {
        response.json().then((result) => {
          if (result.length) {
            let filteredRU = [];
            if (!showOnly) {
              filteredRU = result
                .filter((result) => result.cc.toUpperCase() !== 'RU')
                .filter((res) => res.t === 'hotels' || res.s);
            }
            if (showOnly) {
              filteredRU = result
                .filter((result) => result.cc.toUpperCase() !== 'RU')
                .filter((result) => showOnly.indexOf(result.t) !== -1);
            }
            setHotelCombinedOptions(filteredRU);
            dispatch(setAutocompleteCache({ key: val, value: filteredRU }));
          }
          setLoading(false);
        });
      });
    }
  };
  const setValue = (newValue) => {
    setFieldValue(name, newValue);
  };

  return (
    <Autocomplete
      disablePortal={false}
      value={value || ''}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      isOptionEqualToValue={(evt) => evt.n}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      filterOptions={(x) => x}
      onInputChange={(event, newInputValue) => {
        if (newInputValue.length >= 3) {
          setOpen(true);
          setInputValue(newInputValue);
        }
      }}
      PaperComponent={({ children }) => (
        <Paper className={cn(styles.option, 'font-libre')}>{children}</Paper>
      )}
      options={hotelCombinedOptions}
      groupBy={(option) => option?.t}
      onBlur={() => setHotelCombinedOptions([])}
      getOptionLabel={(option) => {
        return option
          ? `${option.n}${option.p?.length ? ', ' + option.p?.join(', ') : ''}`
          : '';
      }}
      fullWidth
      freeSolo
      renderInput={(params) => (
        <ThemeProvider theme={theme}>
          <TextField
            {...params}
            error={error}
            placeholder={placeholder}
            label={label}
            InputProps={{
              ...params.InputProps,
              startAdornment: <>{icon}</>,
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                </>
              ),
            }}
          />
        </ThemeProvider>
      )}
    />
  );
};
