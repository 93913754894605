export const CABIN_CLASSES = [
  { value: 'ECO', label: 'Economy' },
  { value: 'FLX', label: 'Premium Economy' },
  { value: 'BIZ', label: 'Business Class' },
  { value: 'FST', label: 'First Class' },
];

export const countrySelectOptions = [
  { value: 'AE', label: 'United Arab Emirates' },
  { value: 'AR', label: 'Argentina' },
  {
    value: 'AT',
    label: 'Austria',
  },
  { value: 'AU', label: 'Australia' },
  { value: 'BE', label: 'Belgium' },
  {
    value: 'BO',
    label: 'Bolivia',
  },
  { value: 'BR', label: 'Brazil' },
  { value: 'CA', label: 'Canada' },
  {
    value: 'CH',
    label: 'Switzerland',
  },
  { value: 'CL', label: 'Chile' },
  {
    value: 'CO',
    label: 'Colombia',
  },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'CZ', label: 'Czechia' },
  {
    value: 'DE',
    label: 'Germany',
  },
  { value: 'DK', label: 'Denmark' },
  { value: 'DO', label: 'Dominican Republic' },
  {
    value: 'EC',
    label: 'Ecuador',
  },
  { value: 'ES', label: 'Spain' },
  { value: 'FI', label: 'Finland' },
  {
    value: 'FR',
    label: 'France',
  },
  { value: 'GB', label: 'United Kingdom' },
  { value: 'GR', label: 'Greece' },
  {
    value: 'GT',
    label: 'Guatemala',
  },
  { value: 'HK', label: 'Hong Kong' },
  { value: 'HN', label: 'Honduras' },
  {
    value: 'ID',
    label: 'Indonesia',
  },
  { value: 'IE', label: 'Ireland' },
  { value: 'IL', label: 'Israel' },
  {
    value: 'IN',
    label: 'India',
  },
  { value: 'IT', label: 'Italia' },
  { value: 'JP', label: 'Japan' },
  {
    value: 'KR',
    label: 'South Korea',
  },
  { value: 'MX', label: 'México' },
  { value: 'MY', label: 'Malaysia' },
  {
    value: 'NI',
    label: 'Nicaragua',
  },
  { value: 'NL', label: 'Netherlands' },
  { value: 'NO', label: 'Norway' },
  {
    value: 'NZ',
    label: 'New Zealand',
  },
  { value: 'PA', label: 'Panama' },
  { value: 'PE', label: 'Peru' },
  {
    value: 'PH',
    label: 'Philippines',
  },
  { value: 'PL', label: 'Poland' },
  { value: 'PR', label: 'Puerto Rico' },
  {
    value: 'PT',
    label: 'Portugal',
  },
  { value: 'PY', label: 'Paraguay' },
  { value: 'QA', label: 'Qatar' },
  {
    value: 'RO',
    label: 'Romania',
  },
  { value: 'SA', label: 'Saudi Arabia' },
  { value: 'SE', label: 'Sweden' },
  {
    value: 'SG',
    label: 'Singapore',
  },
  { value: 'SV', label: 'El Salvador' },
  { value: 'TH', label: 'Thailand' },
  {
    value: 'TR',
    label: 'Turkey',
  },
  { value: 'TW', label: 'Taiwan' },
  { value: 'UA', label: 'Ukraine' },
  {
    value: 'US',
    label: 'United States',
  },
  { value: 'UY', label: 'Uruguay' },
  { value: 'VN', label: 'Vietnam' },
  { value: 'ZA', label: 'South Africa' },
];
