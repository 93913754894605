import { httpPut } from '../../utils/httpClient';

export function changeCurrency(code, session) {
  httpPut(
    '/api/user/currency',
    { currency: code },
  ).then(() => {
    session();
  });
}
