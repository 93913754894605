import { Button, createTheme, ThemeProvider } from '@mui/material';
import cn from 'classnames';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { InView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import { httpGet } from '../../utils/httpClient';
import { RecommendationItem } from '../RecommendationsList/RecommendationItem/RecommendationItem';
import styles from './Lists.module.scss';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import HotelIcon from '@mui/icons-material/Hotel';
import PinDropRoundedIcon from '@mui/icons-material/PinDropRounded';
import SmallLogo from '../../public/small-white-logo.svg';
import { BCBreadcrumbs } from '../index';

const CallToSignUp = dynamic(() => import('../CallToSignUp/CallToSignUp'));
const theme = createTheme({
  palette: {
    primary: {
      main: '#00454A',
    },
    secondary: {
      main: '#202020',
    },
  },
  typography: {
    fontFamily: '"Libre Franklin", Arial, sans-serif',
    color: '#202020',
  },
});
const getArticles = async (
  page,
  perPage,
  type,
  subtype,
  loadingHandler,
  location
) => {
  loadingHandler(true);
  return await (
    await httpGet(
      `/api/article?${location ? `&location=${location}` : ''}${
        type ? `&type=${type}` : ''
      }${subtype ? `&subtype=${subtype}` : ''}&page=${page}&size=${perPage}`
    )
  ).json();
};

export function ArticlesList({
  type,
  subtype,
  location,
  heading,
  authCompleted,
}) {
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const userStore = useSelector((state) => state.user.value);

  const perPage = 10;
  useEffect(() => {
    getArticles(currentPage, perPage, type, subtype, setLoading, location).then(
      (res) => {
        setHasMore(!res.last);
        setArticles([...articles, ...res?.content]);
        setLoading(false);
      }
    );
  }, [currentPage]);

  return (
    <ThemeProvider theme={theme}>
      {!!type && !!subtype && (
        <Head>
          <link
            rel="canonical"
            href={`${
              process.env.apiUrl
            }/${type.toLowerCase()}/${subtype.toLowerCase()}`}
          />
        </Head>
      )}
      <div
        className={cn(
          styles.headingContainer,
          type ? styles[type.toLowerCase()] : styles.noType
        )}
      >
        <BCBreadcrumbs
          className="text-white"
          headerText={
            heading
              ? heading
              : `${type ? type : ''} ${subtype ? subtype : ''}  ${
                  location ? location : ''
                }`
          }
        />
        <h1 className={cn(styles.heading, 'font-fragment text-center')}>
          <div className={styles.icon}>
            {type === 'Airlines' && (
              <AirplanemodeActiveIcon className="m-auto text-white" />
            )}
            {type === 'Hotels' && <HotelIcon className="m-auto text-white" />}

            {type === 'Destinations' && (
              <PinDropRoundedIcon className="m-auto text-white" />
            )}
            {type !== 'Destinations' &&
              type !== 'Hotels' &&
              type !== 'Airlines' && (
                <SmallLogo className="m-auto text-white" />
              )}
          </div>
          {heading
            ? heading
            : `${type ? type : ''} ${subtype ? subtype : ''}  ${
                location ? location : ''
              }`}
        </h1>
      </div>
      <div className={cn(styles.listContainer)}>
        {!!articles.length && (
          <InfiniteScroll
            dataLength={articles.length} //This is important field to render the next data
            next={() => setCurrentPage(currentPage + 1)}
            hasMore={hasMore}
          >
            <div className={styles.list}>
              {articles.map((article) => (
                <div key={article.textId} className={styles.recommendationItem}>
                  <RecommendationItem item={article} />
                </div>
              ))}
            </div>
          </InfiniteScroll>
        )}
        {!loading && !articles.length && (
          <div className={styles.emptyList}>
            <h2 className="font-fragment my-4">
              Currently we don`t have any {!subtype && 'content about '}
              {type && type.charAt(0).toLowerCase() + type.slice(1)}{' '}
              {subtype && subtype.charAt(0).toLowerCase() + subtype.slice(1)}
            </h2>
            <p className="mb-8">
              But we are working hard to keep you updated with most fresh
              content!
            </p>

            {userStore && !userStore.userid && (
              <InView triggerOnce>
                {({ inView, ref }) => (
                  <div ref={ref} className="w-full lg:w-2/3 mx-auto mb-8">
                    {inView && (
                      <CallToSignUp
                        country={userStore?.country}
                        authCompleted={authCompleted}
                        sourcePage={`${type ? type : ''} ${subtype ? subtype : ''}` + ' Article List'}
                      />
                    )}
                  </div>
                )}
              </InView>
            )}
            <Button
              variant="outlined"
              color="secondary"
              className="mt-2"
              type="button"
              onClick={() => {
                document.location = '/';
              }}
            >
              Click here to go to frontpage
            </Button>
          </div>
        )}
        {loading && <div className={styles.loader} />}
      </div>
    </ThemeProvider>
  );
}
