export const HAS_ETG = {
  AE: { hasEtg: true, code: 'en-usd' },
  AR: { hasEtg: true, code: 'la-usd' },
  AT: { hasEtg: true, code: 'en-eur' },
  AU: { hasEtg: true },
  BE: { hasEtg: true, code: 'en-eur' },
  BO: { hasEtg: true, code: 'la-usd' },
  BR: { hasEtg: true, code: 'en-usd' },
  CA: { hasEtg: true },
  CH: { hasEtg: true, code: 'en-eur' },
  CL: { hasEtg: true, code: 'la-usd' },
  CO: { hasEtg: true, code: 'la-usd' },
  CR: { hasEtg: true, code: 'la-usd' },
  CZ: { hasEtg: true, code: 'en-eur' },
  DE: { hasEtg: true, code: 'en-eur' },
  DK: { hasEtg: true },
  DO: { hasEtg: true, code: 'la-usd' },
  EC: { hasEtg: true, code: 'la-usd' },
  ES: { hasEtg: true, code: 'la-eur' },
  FI: { hasEtg: true, code: 'en-eur' },
  FR: { hasEtg: true, code: 'en-eur' },
  GB: { hasEtg: true },
  GR: { hasEtg: true, code: 'en-eur' },
  GT: { hasEtg: true, code: 'la-usd' },
  HK: { hasEtg: true, code: 'en-usd' },
  HN: { hasEtg: true, code: 'la-usd' },
  ID: { hasEtg: true, code: 'en-usd' },
  IE: { hasEtg: true },
  IL: { hasEtg: true, code: 'en-usd' },
  IN: { hasEtg: true },
  IT: { hasEtg: true, code: 'en-eur' },
  JP: { hasEtg: true, code: 'en-usd' },
  KR: { hasEtg: true, code: 'en-usd' },
  MX: { hasEtg: true, code: 'la-usd' },
  MY: { hasEtg: true, code: 'en-usd' },
  NI: { hasEtg: true, code: 'la-usd' },
  NL: { hasEtg: true, code: 'en-eur' },
  NO: { hasEtg: true },
  NZ: { hasEtg: true, code: 'en-usd' },
  PA: { hasEtg: true, code: 'la-usd' },
  PE: { hasEtg: true, code: 'la-usd' },
  PH: { hasEtg: true, code: 'en-usd' },
  PL: { hasEtg: true, code: 'en-eur' },
  PR: { hasEtg: true, code: 'la-usd' },
  PT: { hasEtg: true, code: 'en-eur' },
  PY: { hasEtg: true, code: 'la-usd' },
  QA: { hasEtg: true, code: 'en-usd' },
  RO: { hasEtg: true, code: 'en-eur' },
  SA: { hasEtg: true, code: 'en-usd' },
  SE: { hasEtg: true },
  SG: { hasEtg: true, code: 'en-usd' },
  SV: { hasEtg: true, code: 'la-usd' },
  TH: { hasEtg: true, code: 'en-usd' },
  TR: { hasEtg: true, code: 'en-eur' },
  TW: { hasEtg: true, code: 'en-usd' },
  UA: { hasEtg: true, code: 'en-eur' },
  US: { hasEtg: true },
  UY: { hasEtg: true, code: 'la-usd' },
  VN: { hasEtg: true, code: 'en-usd' },
  ZA: { hasEtg: true, code: 'en-usd' },
};
