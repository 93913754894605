import cn from 'classnames';
import React, { createRef, useEffect, useState } from 'react';
import { ImageWithFallback } from '../ImageWithFallback/ImageWithFallback';
import styles from './DestinationItem.module.scss';
import PinDropRoundedIcon from '@mui/icons-material/PinDropRounded';
import { useWindowWidth } from '@react-hook/window-size';

function getLink(country, city) {
  if (country) {
    return `/destinations/${country}/${city}`;
  }
}

export const DestinationItem = ({ item, props }) => {
  const cardRef = React.createRef();
  const [imageHeight, setImageHeight] = useState(0);
  const width = useWindowWidth();
  const image = createRef();
  useEffect(() => {
    setImageHeight(image.current.offsetWidth / 1.78);
  }, [width]);
  return (
    <a
      href={`${getLink(item.countrySafename, item.citySafename)}`}
      {...props}
      aria-label={item.heading}
      className={cn(styles.card, 'relative mb-0')}
    >
      <div className="h-full w-full">
        <div className={cn(styles.icon, 'bg-review-iconDestinations')}>
          <PinDropRoundedIcon className="m-auto text-white" />
        </div>
        <div className="h-full" ref={cardRef}>
          <div
            className={cn(
              styles.text,
              'relative w-full flex flex-col justify-center items-center'
            )}
          >
            <span className={cn(styles.title, 'font-fragment')}>
              {item.heading}
            </span>
            <span className={cn(styles.subTitle, 'font-libre')}>
              {item.subHeading}
            </span>
            <div className={cn(styles.imageContainer)} ref={image} style={{ height: imageHeight }}>
              <ImageWithFallback
                src={process.env.mediaUrl + item.frontImage?.substring(1)}
                layout="fill"
                objectFit="cover"
                quality={95}
                alt={item.heading + ' image'}
                sizes="(max-width: 1024px) 100vw, 520px"
                priority
              />
            </div>
            <p className={cn(styles.typesList, 'font-libre')}>Flights | Hotels | Dining | Activities | Culture &
              more</p>
            <div className={cn(styles.readMore, 'font-libre')}>Read more</div>
          </div>
        </div>
      </div>
    </a>
  );
};
