export const CURRENCY = {
  AED: 'AED',
  ARS: 'ARS',
  AUD: 'AUD',
  BOB: 'BOB',
  BRL: 'BRL',
  CAD: 'CAD',
  CHF: 'CHF',
  CLP: 'CLP',
  COP: 'COP',
  CRC: 'CRC',
  CZK: 'CZK',
  DKK: 'DKK',
  DOP: 'DOP',
  EUR: 'EUR',
  GBP: 'GBP',
  GTQ: 'GTQ',
  HKD: 'HKD',
  HNL: 'HNL',
  IDR: 'IDR',
  ILS: 'ILS',
  INR: 'INR',
  JPY: 'JPY',
  KRW: 'KRW',
  MXN: 'MXN',
  MYR: 'MYR',
  NIO: 'NIO',
  NOK: 'NOK',
  NZD: 'NZD',
  PEN: 'PEN',
  PHP: 'PHP',
  PLN: 'PLN',
  PYG: 'PYG',
  QAR: 'QAR',
  SAR: 'SAR',
  SEK: 'SEK',
  SGD: 'SGD',
  THB: 'THB',
  TRY: 'TRY',
  TWD: 'TWD',
  UAH: 'UAH',
  USD: 'USD',
  UYU: 'UYU',
  VND: 'VND',
  ZAR: 'ZAR',
};
