import cn from 'classnames';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { COUNTRIES } from '../../config';
import arrowStyles from '../assets/Arrow.module.scss';
import styles from './Accordion.module.scss';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  color: '#202020',
  '&:not(:last-child)': {
    borderBottom: 0,
    borderTop: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <KeyboardArrowDownRoundedIcon className={arrowStyles.arrowBlack} />
    }
    {...props}
  />
))(() => ({
  padding: 0,
  border: 0,
  backgroundColor: 'transparent',

  flexDirection: 'row-reverse',

  '& .MuiAccordionSummary-expandIconWrapper': {
    position: 'absolute',
    right: 0,
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: '8px 0 8px 16px'
}));

export const CustomizedAccordion = ({
  children,
  label,
  items,
  collapse,
  currentCountry,
  columnsNumber
}) => {
  const [expandedState, setExpandedState] = useState(false);

  const handleChange = () => {
    setExpandedState(!expandedState);
  };
  useEffect(() => {
    if (collapse) {
      setExpandedState(false);
    }
  }, [collapse]);
  return (
    <div>
      <Accordion expanded={expandedState} onChange={() => handleChange()}>
        <AccordionSummary>
          {children ? (
            <>
              <span
                className={cn(
                  styles.flag32,
                  `flag-32 flag-${currentCountry?.toLowerCase()} mr-2`
                )}
              />
              {COUNTRIES[currentCountry?.toUpperCase()]}
            </>
          ) : (
            label
          )}
        </AccordionSummary>
        <AccordionDetails>
          <div className={cn(
            { [`grid grid-cols-${columnsNumber}`]: columnsNumber}
          )}>
            {children
              ? children
              : items.map((item, idx) => (
                  <div className={cn(styles.itemChild ,'p-1')} key={idx}>
                    {item.children?.length ? (
                      <CustomizedAccordion
                        items={item.children}
                        label={item.label}
                      />
                    ) : item.href ? (
                      <a href={item.href} key={item.label}>
                        {item.label}
                      </a>
                    ) : (
                      <a
                        onClick={() => {
                          item.action();
                          setExpandedState(false);
                        }}
                        key={item.label}
                      >
                        {item.label}
                      </a>
                    )}
                  </div>
                ))}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
