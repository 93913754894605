import cn from 'classnames';
import styles from './StarRating.module.scss';

export const StarRating = ({
  rating,
  label,
  hideTextRating = false,
  small = false,
  inactive = false,
}) => {
  return (
    <div className={styles.bcReviewScores}>
      <div
        className={cn(
          styles.stars0,
          { [styles.stars0Small]: small },
          { [styles.stars0Inactive]: inactive },
          styles[`stars${rating}`],
          { 'mr-2': !hideTextRating }
        )}
      />
      <span>{label}</span>
      {!hideTextRating && (
        <>
          &nbsp;{'-'}&nbsp;
          <span>{rating}/10</span>
        </>
      )}
    </div>
  );
};
