import cn from 'classnames';
import styles from './CircleRating.module.scss';
import Tooltip from '@mui/material/Tooltip';

export const CircleRating = ({ rating, text, helpTooltip }) => {
  return (
    <div className={styles.bcReviewScores}>
      <div className={cn(styles['user-review'])}>
        <div className={cn(styles['user-rating'], styles[`user${rating}`])} />
        {helpTooltip && (
          <Tooltip enterTouchDelay={1} enterDelay={0} title={helpTooltip}       leaveTouchDelay={10000}>
            <p className={styles.tooltipHandler} dangerouslySetInnerHTML={{ __html: text }}/>
          </Tooltip>
        )}
        {!helpTooltip && <span dangerouslySetInnerHTML={{ __html: text }}/>}
      </div>
    </div>
  );
};
