import styles from './VintageButton.module.scss';
import Link from 'next/link';
import cn from 'classnames';

export const VintageButton = ({ label, onClick, href, dark = false }) => {
  return href ? <Link href={href} passHref>
      <a aria-label={label} role="button">
        <div className={cn(styles.vintageButtonWrapper, { [styles.dark]: dark })}>
          <div className={styles.heading}>
            <div className={styles.leftSide} />
            <div className={styles.centre} />
            <div className={styles.rightSide} />
          </div>
          <span>{label}</span>
        </div>
      </a>
    </Link> :
    <button
      onClick={onClick}
      type='button'
      aria-label={label}
      className={cn(styles.vintageButtonWrapper, { [styles.dark]: dark })} >
      <div className={styles.heading}>
        <div className={styles.leftSide} />
        <div className={styles.centre} />
        <div className={styles.rightSide} />
      </div>
      <span>{label}</span>
    </button>;
};
