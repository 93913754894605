import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { httpGet } from '../../utils/httpClient';
import styles from './DestinationList.module.scss';
import * as PropTypes from 'prop-types';
import { BCBreadcrumbs } from '../index';
import { RecommendationItem } from '../RecommendationsList/RecommendationItem/RecommendationItem';

const getDestinations = async (country, page, perPage, loadingHandler) => {
  loadingHandler(true);
  const requestUrl = country
    ? `/api/destinations?&country=${country}&page=${page}&size=${perPage}`
    : `/api/destinations?page=${page}&size=${perPage}`;
  return await (await httpGet(requestUrl)).json();
};

DestinationList.propTypes = {
  country: PropTypes.string,
  heading: PropTypes.string,
};

export function DestinationList({ country, heading }) {
  const [currentPage, setCurrentPage] = useState(0);
  const [destinations, setDestinations] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countryName, setCountryName] = useState(false);

  const perPage = 10;
  useEffect(() => {
    getDestinations(country, currentPage, perPage, setLoading).then((res) => {
      setHasMore(!res.last);
      setDestinations([...destinations, ...res.content]);
      if (country) {
        setCountryName(res.content[0].country);
      }
      setLoading(false);
    });
  }, [currentPage]);

  function getArticleItem(review) {
    return {
      type: 'Destinations',
      subtype: 'Destination',
      textId: review.citySafename,
      image: review.frontImage?.substring(1),
      overline: `${review.country} ${
        review.city === '-' ? '' : ', ' + review.city
      }`,
      title: review.heading,
      content: review.intro,
      country: review.countrySafename,
      city: review.citySafename === '-' ? '' : review.citySafename,
    };
  }

  return (
    <div>
      <div className={cn(styles.headingContainer)}>
        <BCBreadcrumbs className="text-white"
          headerText={countryName ? `Destinations in ${countryName}` : heading} />
        <h1 className={cn(styles.heading, 'font-fragment text-center')}>
          {countryName ? `Destinations in ${countryName}` : heading}
        </h1>
      </div>
      <div className={cn(styles.listContainer, 'container')}>
        <InfiniteScroll
          dataLength={destinations.length}
          next={() => setCurrentPage(currentPage + 1)}
          hasMore={hasMore}
        >
         
          <div className={styles.list}>
            {destinations.map((review, index) => {
              return (
                <div key={index} className={styles.recommendationItem}>
                  <RecommendationItem item={getArticleItem(review)} />
                </div>
              );
            })}
          </div>
        </InfiniteScroll>
        {loading && <div className={styles.loader} />}
      </div>
    </div>
  );
}
