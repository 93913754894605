import cn from 'classnames';
import React, { useState } from 'react';
import { COUNTRIES } from '../../config';
import { CustomizedAccordion } from '../Accordion/Accordion';
import styles from '../Accordion/Accordion.module.scss';
import { changeCountry } from '../utils/changeCountry';

export const CountryAccordion = ({ currentCountry, session, columnsNumber }) => {
  const [collapse, setCollapse] = useState(0);
  return (
    <CustomizedAccordion currentCountry={currentCountry} collapse={collapse}>
      <div className={cn(
        { [`grid grid-cols-${columnsNumber}`]: columnsNumber }
      )}>
        {Object.keys(COUNTRIES).map((country) => (
          <div key={country} className="relative font-libre">
            <div
              className="flex items-center cursor-pointer"
              style={{ minHeight: 32 }}
              onClick={() => {
                changeCountry(country, session);
                setCollapse(collapse + 1);
              }}
            >
              <span
                className={cn(
                  styles.flag32,
                  `flag-32 flag-${country.toLowerCase()} mr-2`
                )}
              />
              {COUNTRIES[country]}
            </div>
          </div>
        ))}
      </div>
    </CustomizedAccordion>
  );
};
