import { Box } from '@mui/material';
import FlightIcon from '@mui/icons-material/Flight';
import HotelIcon from '@mui/icons-material/Hotel';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { InView } from 'react-intersection-observer';

const FlightSearch = dynamic(() => import('../FlightSearch/FlightSearch'));
const HotelSearch = dynamic(() => import('../HotelSearch/HotelSearch'));
const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent'
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: 'transparent'
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(16),
    height: 48,
    position: 'relative',
    bottom: '-1px',
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
      color: '#fff'
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'transparent'
    },
    '&.MuiButtonBase-root': {
      padding: 16,
      height: 48,
      maxHeight: 48,
      minHeight: 48
    },
    [theme.breakpoints.down('md')]: {
      '&.MuiButtonBase-root': {
        width: '50%'
      }
    }
  })
);
export const SearchBox = ({
                            type,
                            activeTab,
                            hotelSearchValue,
                            flightSearchValue,
                            searchSource
                          }) => {
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  const [tab, setTab] = useState(activeTab);
  const [activeType, setActiveType] = useState(type);
  const [flightSearchState, setFlightSearchState] = useState(null);
  useEffect(() => {
    if (flightSearchValue) {
      setTimeout(() => {
        setFlightSearchState(flightSearchValue);
      }, 100);
    }
  }, [flightSearchValue]);
  useEffect(() => {
    setTab(activeTab);
  }, [activeTab]);
  useEffect(() => {
    setActiveType(type);
  }, [type]);
  return (
    <Box sx={{ width: '100%', filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05))' }}>
      <Box
        sx={{
          borderBottom: 8,
          borderColor: tab ? '#7E183D' : '#00454A',
          background: 'transparent'
        }}
      >
        <StyledTabs
          value={tab}
          onChange={handleChange}
        >
          <StyledTab
            icon={
              <div
                style={{ transform: 'skew(-30deg)' }}
                className={'border border-white rounded-full mr-2'}
              >
                <FlightIcon
                  style={{
                    width: '30px',
                    height: '30px',
                    padding: '6px'
                  }}
                />
              </div>
            }
            iconPosition="start"
            label={<div style={{ transform: 'skew(-30deg)' }}>Flight</div>}
            className={'font-libre'}
            sx={{
              background: tab ? '#012426' : '#00454A',
              zIndex: 1,
              color: 'white',
              height: '50px',
              minWidth: '162px',
              transform: 'skew(30deg)',
              left: '-15px',
              borderTopRightRadius: '8px'
            }}
          />
          <StyledTab
            icon={
              <div
                style={{ transform: 'skew(-30deg)' }}
                className={'border border-white rounded-full mr-2'}
              >
                <HotelIcon
                  style={{
                    width: '30px',
                    height: '30px',
                    padding: '6px'
                  }}
                />
              </div>
            }
            iconPosition="start"
            className={'font-libre'}
            label={<div style={{ transform: 'skew(-30deg)' }}>Hotel</div>}
            sx={{
              background: tab ? '#7E183D' : '#580E28',
              color: 'white',
              height: '50px',
              minWidth: '162px',
              transform: 'skew(30deg)',
              left: '-22px',
              borderTopRightRadius: '8px'
            }}
          />
        </StyledTabs>
      </Box>
      <TabPanel value={tab} index={0}>
        <Box
          sx={{
            width: '100%',
            background: 'white',
            padding: ['1rem 0', '1rem 2rem', '1rem 2rem'],
            minHeight: ['500px', '120px']
          }}
        >
          <div className="w-full">
            <FlightSearch
              collapsed={false}
              viewMode={activeType}
              disablePortal={false}
              flightSearchValueProp={flightSearchState}
              searchSource={searchSource}
            />
          </div>
        </Box>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <Box
          sx={{
            width: '100%',
            background: 'white',
            padding: ['1rem 0', '1rem 2rem', '1rem 2rem'],
            minHeight: '120px'
          }}
        >
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref} className="w-full">
                {inView && (
                  <HotelSearch
                    viewMode={'mainSearch'}
                    value={hotelSearchValue}
                  />
                )}
              </div>
            )}
          </InView>
        </Box>
      </TabPanel>
    </Box>
  );
};
