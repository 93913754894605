// pages/404.js
import cn from 'classnames';
import Head from 'next/head';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { httpGet } from '../../utils/httpClient';
import styles from './Lists.module.scss';
import { ReviewItem } from './ReviewItem';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import { BCBreadcrumbs } from '../index';
import { Form, Formik } from 'formik';
import {
  createTheme,
  debounce,
  Dialog,
  MenuItem,
  Select,
  ThemeProvider,
} from '@mui/material';
import { CABIN_CLASSES } from '../../utils/cabinClasses';
import qs from 'qs';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import SortIcon from '@mui/icons-material/Sort';
import Tooltip from '@mui/material/Tooltip';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import { useWindowWidth } from '@react-hook/window-size';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import { AirlineAutocomplete } from '../AirlineAutocomplete/AirlineAutocomplete';
import { usePrevious } from '../../utils/usePrevious';
import cloneDeep from 'lodash/cloneDeep';

const theme = createTheme({
  shape: {
    borderRadius: 12,
  },
  palette: {
    primary: {
      main: '#00454A',
    },
    dark: {
      main: '#0a0a0a',
    },
  },
  typography: {
    fontFamily: '"Libre Franklin", sans-serif',
  },
});
const getReviews = async (page, perPage, loadingHandler, filters) => {
  loadingHandler(true);
  let query = {};
  if (filters?.airline) {
    filters.code = filters.airline.code;
    delete filters.airline;
  }
  if (filters && Object.keys(filters).length) {
    query = qs.stringify(filters);
  }
  if (
    filters &&
    Object.keys(filters).length &&
    filters.sort &&
    Object.keys(filters.sort).length
  ) {
    if (filters.sort?.rating) {
      query += `&sort=rating,${filters.sort?.rating}`;
    }
    if (filters.sort?.airline) {
      query += `&sort=airline.name,${filters.sort?.airline}`;
    }
  }
  const url = Object.keys(query).length
    ? `/api/airline/review?page=${page}&size=${perPage}&${query}`
    : `/api/airline/review?page=${page}&size=${perPage}`;

  return await (await httpGet(url)).json();
};
const loadAlliances = () => {
  return httpGet('/api/airline/alliance').then((res) => res.json());
};

export function AirlineReviewsList() {
  const defaultFilters = {
    alliance: 'Select alliance',
    class: 'Any class',
    airline: '',
    sort: {
      airline: null,
      rating: null,
    },
  };
  const [currentPage, setCurrentPage] = useState(0);
  const [reviews, setReviews] = useState([]);
  const [alliances, setAlliances] = useState([]);
  const [filters, setFilters] = useState(defaultFilters);
  const [cabinClasses, setCabinClasses] = useState([]);

  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mobileFilters, setMobileFilters] = useState(false);
  const width = useWindowWidth();
  const renderFilters = ({ setFieldValue, values }) => {
    return (
      <>
        <div className={styles.filterSmaller}>
          <AirlineAutocomplete
            name="airline"
            type="text"
            fullWidth
            value={values.airline}
            placeholder="Airline"
            primaryColor="#202020"
            setFieldValue={setFieldValue}
          />
        </div>
        <div className={cn(styles.filterSmaller)}>
          {cabinClasses && (
            <Select
              value={values.class}
              onChange={(event) => {
                setFieldValue('class', event.target.value);
              }}
              fullWidth
              defaultValue={'Any class'}
              name={'class'}
            >
              <MenuItem value={'Any class'}>Any class</MenuItem>
              {cabinClasses.map((cabin) => (
                <MenuItem key={cabin.value} value={cabin.value}>
                  {cabin.label}
                </MenuItem>
              ))}
            </Select>
          )}
        </div>
        <div className={cn(styles.filterSmaller)}>
          <Select
            value={values.alliance}
            onChange={(event) => {
              setFieldValue('alliance', event.target.value);
            }}
            fullWidth
            defaultValue={'Select alliance'}
            name={'alliance'}
          >
            <MenuItem value={'Select alliance'}>Select alliance</MenuItem>
            {alliances.slice(0, 3).map((alliance) => (
              <MenuItem key={alliance} value={alliance}>
                {alliance}
              </MenuItem>
            ))}
          </Select>
        </div>
      </>
    );
  };

  const perPage = 10;
  const prevFilters = usePrevious({ filters });
  const validateFilters = (f) => {
    const filters = cloneDeep(f);
    if (filters.sort?.airline === null) {
      delete filters.sort.airline;
    }
    if (filters.sort?.rating === null) {
      delete filters.sort.rating;
    }
    if (filters.alliance === 'Select alliance') {
      delete filters.alliance;
    }
    if (filters.class === 'Any class') {
      delete filters.class;
    }
    if (!filters.airline) {
      delete filters.airline;
    }
    if (filters.sort && !Object.keys(filters.sort).length) {
      delete filters.sort;
    }
    return filters;
  };
  useEffect(() => {
    if (JSON.stringify(defaultFilters) !== JSON.stringify(filters)) {
      const f = validateFilters(filters);
      getReviews(currentPage, perPage, setLoading, f).then((res) => {
        setHasMore(!res.last);
        setReviews([...reviews, ...res.content]);
        setLoading(false);
      });
    } else {
      getReviews(currentPage, perPage, setLoading).then((res) => {
        setHasMore(!res.last);
        setReviews([...reviews, ...res.content]);
        setLoading(false);
      });
    }
  }, [currentPage]);
  useEffect(() => {
    if (
      JSON.stringify(defaultFilters) !== JSON.stringify(filters) &&
      JSON.stringify(prevFilters.filters) !== JSON.stringify(filters) &&
      !filters.resetFilters
    ) {
      setLoading(true);
      const f = validateFilters(filters);
      getReviews(currentPage, perPage, setLoading, f).then((res) => {
        setHasMore(!res.last);
        setReviews([...res.content]);
        setLoading(false);
      });
    }
    if (filters.resetFilters) {
      setFilters(defaultFilters);
      setCurrentPage(0);
      getReviews(0, perPage, setLoading).then((res) => {
        setHasMore(!res.last);
        setReviews([...res.content]);
        setLoading(false);
      });
    }
  }, [filters]);
  useEffect(() => {
    loadAlliances().then((res) => {
      setAlliances(res.map((alliance) => alliance.name));
    });
    const cabinClasses = [...CABIN_CLASSES].reverse();
    cabinClasses.pop();
    setCabinClasses(cabinClasses);
  }, []);

  return (
    <>
      <Head>
        <link rel="canonical" href={`${process.env.apiUrl}/airlines/reviews`} />
      </Head>
      <div className={cn(styles.headingContainer, styles.airline)}>
        <BCBreadcrumbs className="text-white" headerText="Reviews" />
        <h1 className={cn(styles.heading, 'font-fragment text-center')}>
          <div className={styles.airlineIcon}>
            <AirplanemodeActiveIcon className="m-auto text-white" />
          </div>
          Airline reviews
        </h1>
        {width && (
          <ThemeProvider theme={theme}>
            <Formik
              enableReinitialize
              initialValues={filters}
              validate={debounce((value) => {
                setFilters({ ...value });
              }, 1000)}
            >
              {({ setFieldValue, values, resetForm }) => (
                <Form>
                  {width >= 1024 && (
                    <div className={styles.filtersContainer}>
                      {renderFilters({ setFieldValue, values })}
                      <Tooltip title="Sort by rating">
                        <IconButton
                          color="inherit"
                          type="reset"
                          onClick={() => {
                            if (filters.sort?.rating === 'desc') {
                              setFieldValue('sort', {
                                ...filters.sort,
                                rating: 'asc',
                              });
                            }
                            if (filters.sort?.rating === 'asc') {
                              setFieldValue('sort', {
                                ...filters.sort,
                                rating: null,
                              });
                            }
                            if (!filters.sort?.rating) {
                              setFieldValue('sort', {
                                ...filters.sort,
                                rating: 'desc',
                              });
                            }
                          }}
                          aria-label="clear"
                          sx={{
                            border: '1px solid #fff',
                            borderRadius: '50%',
                            color: '#fff',
                            width: '56px',
                            marginRight: '1rem',
                          }}
                        >
                          <SortIcon />
                          {filters.sort?.rating === 'asc' && (
                            <ArrowRightAltIcon
                              sx={{
                                position: 'absolute',
                                right: '-2px',
                                transform: 'rotate(90deg)',
                              }}
                            />
                          )}
                          {filters.sort?.rating === 'desc' && (
                            <ArrowRightAltIcon
                              sx={{
                                position: 'absolute',
                                right: '-2px',
                                transform: 'rotate(-90deg)',
                              }}
                            />
                          )}
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Sort alphabetically">
                        <IconButton
                          color="inherit"
                          type="reset"
                          onClick={() => {
                            if (filters.sort?.airline === 'desc') {
                              setFieldValue('sort', {
                                ...filters.sort,
                                airline: 'asc',
                              });
                            }
                            if (filters.sort?.airline === 'asc') {
                              setFieldValue('sort', {
                                ...filters.sort,
                                airline: null,
                              });
                            }
                            if (!filters.sort?.airline) {
                              setFieldValue('sort', {
                                ...filters.sort,
                                airline: 'desc',
                              });
                            }
                          }}
                          aria-label="clear"
                          sx={{
                            border: '1px solid #fff',
                            borderRadius: '50%',
                            color: '#fff',
                            width: '56px',
                            marginRight: '1rem',
                          }}
                        >
                          <SortByAlphaIcon />
                          {filters.sort?.airline === 'asc' && (
                            <ArrowRightAltIcon
                              sx={{
                                position: 'absolute',
                                right: '-2px',
                                transform: 'rotate(90deg)',
                              }}
                            />
                          )}
                          {filters.sort?.airline === 'desc' && (
                            <ArrowRightAltIcon
                              sx={{
                                position: 'absolute',
                                right: '-2px',
                                transform: 'rotate(-90deg)',
                              }}
                            />
                          )}
                        </IconButton>
                      </Tooltip>
                      {JSON.stringify(defaultFilters) !==
                        JSON.stringify(filters) && (
                        <Tooltip title="Reset filters">
                          <IconButton
                            color="inherit"
                            type="reset"
                            onClick={() => {
                              resetForm({ values: defaultFilters });
                              setFilters({ resetFilters: true });
                            }}
                            aria-label="clear"
                            sx={{
                              border: '1px solid #fff',
                              borderRadius: '50%',
                              color: '#fff',
                              width: '56px',
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  )}
                  {width < 1024 && (
                    <div className={styles.mobileFiltersWrapper}>
                      <div
                        className={styles.filtersButton}
                        onClick={() => setMobileFilters(true)}
                      >
                        <TuneRoundedIcon className={'mr-2'} /> Filters
                      </div>
                      <Dialog
                        open={mobileFilters}
                        fullWidth
                        PaperProps={{
                          style: {
                            margin: 0,
                            width: '100%',
                            bottom: 0,
                            position: 'absolute',
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                          },
                        }}
                      >
                        <div className={styles.filtersContainerMobile}>
                          <IconButton
                            color="inherit"
                            onClick={() => setMobileFilters(false)}
                            aria-label="close"
                            className={styles.closeMobileFilters}
                          >
                            <CloseIcon />
                          </IconButton>
                          {renderFilters({ setFieldValue, values })}
                        </div>
                      </Dialog>

                      <div className={styles.sorting}>
                        <Tooltip title="Sort by rating">
                          <IconButton
                            color="inherit"
                            type="reset"
                            onClick={() => {
                              if (filters.sort?.rating === 'desc') {
                                setFieldValue('sort', {
                                  ...filters.sort,
                                  rating: 'asc',
                                });
                              }
                              if (filters.sort?.rating === 'asc') {
                                setFieldValue('sort', {
                                  ...filters.sort,
                                  rating: null,
                                });
                              }
                              if (!filters.sort?.rating) {
                                setFieldValue('sort', {
                                  ...filters.sort,
                                  rating: 'desc',
                                });
                              }
                            }}
                            aria-label="clear"
                            sx={{
                              border: '1px solid #fff',
                              borderRadius: '50%',
                              color: '#fff',
                              width: '42px',
                              marginRight: '1.25rem',
                            }}
                          >
                            <SortIcon />
                            {filters.sort?.rating === 'asc' && (
                              <ArrowRightAltIcon
                                sx={{
                                  position: 'absolute',
                                  right: '-21px',
                                  transform: 'rotate(90deg)',
                                }}
                              />
                            )}
                            {filters.sort?.rating === 'desc' && (
                              <ArrowRightAltIcon
                                sx={{
                                  position: 'absolute',
                                  right: '-21px',
                                  transform: 'rotate(-90deg)',
                                }}
                              />
                            )}
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Sort alphabetically">
                          <IconButton
                            color="inherit"
                            type="reset"
                            onClick={() => {
                              if (filters.sort?.airline === 'desc') {
                                setFieldValue('sort', {
                                  ...filters.sort,
                                  airline: 'asc',
                                });
                              }
                              if (filters.sort?.airline === 'asc') {
                                setFieldValue('sort', {
                                  ...filters.sort,
                                  airline: null,
                                });
                              }
                              if (!filters.sort?.airline) {
                                setFieldValue('sort', {
                                  ...filters.sort,
                                  airline: 'desc',
                                });
                              }
                            }}
                            aria-label="clear"
                            sx={{
                              border: '1px solid #fff',
                              borderRadius: '50%',
                              color: '#fff',
                              width: '42px',
                              marginRight: '1.25rem',
                            }}
                          >
                            <SortByAlphaIcon />
                            {filters.sort?.airline === 'asc' && (
                              <ArrowRightAltIcon
                                sx={{
                                  position: 'absolute',
                                  right: '-21px',
                                  transform: 'rotate(90deg)',
                                }}
                              />
                            )}
                            {filters.sort?.airline === 'desc' && (
                              <ArrowRightAltIcon
                                sx={{
                                  position: 'absolute',
                                  right: '-21px',
                                  transform: 'rotate(-90deg)',
                                }}
                              />
                            )}
                          </IconButton>
                        </Tooltip>
                        {JSON.stringify(defaultFilters) !==
                          JSON.stringify(filters) && (
                          <Tooltip title="Reset filters">
                            <IconButton
                              color="inherit"
                              type="reset"
                              onClick={() => {
                                resetForm({ values: defaultFilters });
                                setFilters({ resetFilters: true });
                              }}
                              aria-label="clear"
                              sx={{
                                border: '1px solid #fff',
                                borderRadius: '50%',
                                color: '#fff',
                                width: '42px',
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </ThemeProvider>
        )}
      </div>
      <div className={cn(styles.listContainer, 'container')}>
        <InfiniteScroll
          dataLength={reviews.length} //This is important field to render the next data
          next={() => setCurrentPage(currentPage + 1)}
          hasMore={hasMore}
        >
          <div className={styles.list}>
            {reviews.map((review, index) => {
              return (
                <div key={index} className={styles.review}>
                  <ReviewItem
                    type="Airline"
                    review={review}
                    url={`/airlines/reviews/${review.textId}`}
                  />
                </div>
              );
            })}
          </div>
        </InfiniteScroll>
        {loading && <div className={styles.loader} />}
        {!reviews.length && !loading && (
          <div className={styles.noReviewsFound}>No reviews found...</div>
        )}
      </div>
      ;
    </>
  );
}
