import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { styled } from '@mui/material/styles';
// import { BC } from '../../utils/flightUtils';


// eslint-disable-next-line no-unused-vars
const _defaultGetTextGenerator = (param, query) => null;
const _defaultGetDefaultTextGenerator = path => path;

// Pulled out the path part breakdown because its
// going to be used by both `asPath` and `pathname`
const generatePathParts = pathStr => {
  const pathWithoutQuery = pathStr.split("?")[0];
  return pathWithoutQuery.split("/")
                         .filter(v => v.length > 0);
}

const StyledBreadcrumbs = styled((props) => (
  <Breadcrumbs
    {...props}
    aria-label="breadcrumb"
  />
))({
  '& .MuiBox-root': {
   
  },
  '& .MuiBreadcrumbs-ol': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    listStyle: 'none',
    width: '100%',
    marginTop: '1rem',
    color: 'inherit',
    padding: '0 1.25rem'
  },
  '& .MuiBreadcrumbs-li': {
    p: {
      margin: 0,
      marginTop: '2px',
      padding: 0,
      width: '100%',
      color: '#9b9b9b',
    },
    svg: {
      fontSize: '1rem'
    }
  },
});

export const BCBreadcrumbs = ({
  className = '',
  getTextGenerator=_defaultGetTextGenerator,
  getDefaultTextGenerator=_defaultGetDefaultTextGenerator,
  headerText
}) => {
  const router = useRouter();

  const breadcrumbs = React.useMemo(function generateBreadcrumbs() {
    const asPathNestedRoutes = generatePathParts(router.asPath);
    // const pathnameNestedRoutes = generatePathParts(router.pathname);

    const crumblist = asPathNestedRoutes.map((subpath, idx) => {
      // Pull out and convert "[post_id]" into "post_id"
 
      const param = asPathNestedRoutes[idx].replace("[", "").replace("]", "").replace("_", " ").replace("-", " ");

      const href = "/" + asPathNestedRoutes.slice(0, idx + 1).join("/");
      return {
        href,
        textGenerator: getTextGenerator(param, router.query),
        text: getDefaultTextGenerator(subpath, href)
      }; 
    })

    return [{ href: "/", text: "Home" }, ...crumblist];
  }, [router.asPath, router.pathname, router.query, getTextGenerator, getDefaultTextGenerator]);

  const schemaObject = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": breadcrumbs.map((crumb, idx) => (
      {
        "@type": "ListItem",
        "position": idx + 1,
        "item":
          {
            "@id": process.env.apiUrl + crumb.href,
            "name": crumb.text
          }
      }
    ))
  };

  return (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaObject) }}>
      </script>
    
      <StyledBreadcrumbs className={className} separator={<NavigateNextIcon fontSize="inherit" />}>
        {breadcrumbs.map((crumb, idx) => (
          <Crumb {...crumb} key={idx} last={idx === breadcrumbs.length - 1} headerText={headerText} />
        ))}
      </StyledBreadcrumbs>
    </>
  );
}

function Crumb({ text: defaultText, textGenerator, href, last=false, headerText }) {

  const [text, setText] = React.useState(defaultText);

  // useEffect(async () => {
  //   // If `textGenerator` is nonexistent, then don't do anything
  //   // eslint-disable-next-line no-extra-boolean-cast
  //   if (!Boolean(textGenerator)) { return; }
  //   // Run the text generator and set the text again
  //   const finalText = await textGenerator();
  //   setText(finalText);
  // }, [textGenerator]);

  useEffect(() => {
    let isMounted = true; // To prevent state update after unmount
  
    const generateText = async () => {
      if (!textGenerator) return;
  
      try {
        const finalText = await textGenerator();
        if (isMounted) {
          setText(finalText); // Only set text if component is still mounted
        }
      } catch (error) {
        console.error("Error generating text:", error);
      }
    };
  
    generateText();
  
    return () => {
      isMounted = false; // Cleanup: component unmounted, prevent setting state
    };
  }, [textGenerator]);

  if (last) {
    return <Typography className="font-libre text-xs normal-case">{headerText || text}</Typography>
  }

  return (
    <Link underline="hover" color="inherit" href={href} className="font-libre text-xs underline capitalize">
      {text}
    </Link>
  );
}
