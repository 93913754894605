import styles from './VintageHeader.module.scss';
import cn from 'classnames';

export const VintageHeader = ({ showHole = true, isDark = false }) => {

  return <div className={cn(styles.container, { [styles.dark]: isDark })}>
    <div className={styles.leftSide} />
    <div className={styles.centre}>
      {showHole && <div className={styles.hole} />}
    </div>
    <div className={styles.rightSide} />

  </div>;

};
