import cn from 'classnames';
import React from 'react';
import { DropDownItem } from './DropDownItem/Item';
import { createPopper } from '@popperjs/core';
import styles from './Dropdown.module.scss';
import arrowStyles from '../assets/Arrow.module.scss';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { useSelector } from 'react-redux';

export const DropDown = ({
  label,
  items,
  child = false,
  href = '#',
  rel,
  transparentHeader,
  columnsNumber,
  isForumShown = false,
}) => {
  const userStore = useSelector((state) => state.user.value);
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const dropdownHandlerRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(dropdownHandlerRef.current, popoverDropdownRef.current, {
      placement: child ? 'right-start' : 'bottom',
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  return (
    <>
      <div
        onMouseEnter={openDropdownPopover}
        onMouseLeave={closeDropdownPopover}
      >
        <div
          className={cn(
            styles.dropDownContainer,
            {
              'px-4 py-2': !!child,
            },
            'font-libre',
            'relative',
            'flex',
            'w-full'
          )}
          ref={dropdownHandlerRef}
        >
          <a
            className={cn(
              {
                [styles.dropDownLinkLight]: !child && transparentHeader,
                [styles.dropDownLink]: child || !transparentHeader,
              },
              'w-full'
            )}
            aria-label={label + ' menu item'}
            href={href}
          >
            <span>{label}</span>
          </a>
          <KeyboardArrowDownRoundedIcon
            className={cn({
              [arrowStyles.arrow]: !child && transparentHeader,
              [arrowStyles.arrowBlack]: child,
              [arrowStyles.arrowBlackHeading]: !child && !transparentHeader,
            })}
          />
        </div>
        <ul
          ref={popoverDropdownRef}
          className={cn(
            {
              block: dropdownPopoverShow,
              hidden: !dropdownPopoverShow,
              [`grid grid-cols-${columnsNumber}`]: columnsNumber
            },
            'text-black',
            'z-50',
            'float-left',
            'list-none',
            'text-left',
            'bg-white',
            styles.card
          )}
        >
          {isForumShown && (
            <li className="relative">
              <a
                href="/forum"
                aria-label='Forum menu item'
                className={styles.dropDownLink}
                // className={cn('px-4 hover:no-underline', {
                //   'text-black': !transparentHeader,
                //   'text-white': transparentHeader,
                //   [styles.darkFont]: !transparentHeader,
                // })}
              >
                <div className="px-4 py-2">Forum</div>
              </a>
            </li>
          )}
          {items.length &&
            items.map((item) => (
              <li key={item.label} className="relative">
                {item.children?.length ? (
                  <DropDown
                    label={item.label}
                    items={item.children}
                    child
                    href={item.href}
                    action={item.action}
                  />
                ) : item.href ? (
                  item.isShownForLoggedInUsers ?
                    (userStore?.userid ? 
                      <a className={styles.dropDownLink} href={item.href} rel={rel} aria-label={item.label + 'menu item'}>
                        <DropDownItem className="px-4 py-2" item={item} />
                      </a>
                     : null) : 
                      <a className={styles.dropDownLink} href={item.href} rel={rel} aria-label={item.label + 'menu item'}>
                        <DropDownItem className="px-4 py-2" item={item} />
                      </a>
                    
                ) : (
                  <button
                    role="menuitem"
                    aria-label={item.label}
                    className={styles.dropDownLink}
                    onClick={() => {
                      item.action();
                      closeDropdownPopover();
                    }}
                  >
                    <DropDownItem className="px-4 py-2" item={item} />
                  </button>
                )}
              </li>
            ))}
        </ul>
      </div>
    </>
  );
};
