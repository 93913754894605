import { createTheme, ThemeProvider } from '@mui/material';
import cn from 'classnames';
import Image from 'next/image';
import React from 'react';
import styles from './TermsConditions.module.scss';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00454A',
    },
    secondary: {
      main: '#202020',
    },
  },
  typography: {
    fontFamily: '"Libre Franklin", sans-serif',
    color: '#202020',
  },
});
export const TermsConditions = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className={styles.top}>
        <Image
          className={styles.topImage}
          src={`${process.env.mediaUrl}assets/cookie_front.jpeg`}
          layout="fill"
          objectFit={'cover'}
          objectPosition={'center 70%'}
        />
      </div>
      <div className={cn('container font-libre')}>
        <div className={styles.content}>
          <div className="row section message">
            <div className="column large-16 large-centered">
              <h1 className="font-garamond">Terms &amp; Conditions</h1>
              <p>for BusinessClass.com V02.03 Dated 10.07.2024</p>

              <h3>1. Acceptance of Terms</h3>
              <p>
                Please read these terms and conditions of use carefully before
                accessing, using or obtaining any materials, information,
                products or services. By accessing the BusinessClass.com
                website, mobile or tablet application, or any other feature or
                other BusinessClass.com platform (collectively, &quot;Our
                Website&quot;), you agree to be bound by these terms and
                conditions (&quot;Terms&quot;) and our Privacy Policy. If you do
                not accept all of these Terms, then you may not use Our Website.
                In these Terms, &quot;we&quot;, &quot;us&quot;, &quot;our&quot;
                and &quot;BusinessClass.com&quot; refers to BusinessClass.com,
                and &quot;you&quot; and &quot;your&quot; refers to you, the user
                of Our Website.
              </p>

              <h3>2. Changes to Terms</h3>
              <p>
                We may modify these Terms for any reason at any time by posting
                a new version on Our Website. These changes do not affect rights
                and obligations that arose prior to such changes. Your continued
                use of Our Website following the posting of modified Terms will
                be subject to the Terms in effect at the time of your use.
                Please review these Terms periodically for changes. If you
                object to any provision of these Terms or any subsequent
                modifications to these Terms or become dissatisfied with Our
                Website in any way, your only recourse is to immediately
                terminate use of Our Website.
              </p>

              <h3> 3. We Do Not Sell Travel Products</h3>
              <p>
                Our Website is a travel search engine. BusinessClass.com does
                not provide, own, or control any of the travel services and
                products that you can access through Our Website, such as
                flights, accommodations, rental cars, packages, or travel
                insurance (the &quot;Travel Products&quot;). The Travel Products are
                owned, controlled, or made available by third parties (the
                &quot;Travel Providers&quot;) either directly (e.g., airline) or as an
                agent (e.g., online travel agency). The Travel Providers are
                responsible for the Travel Products. The Travel Provider&apos;s terms
                and privacy policies apply to your booking, so you must agree to
                and understand those terms. Furthermore, the terms of the actual
                travel provider (airline, hotel, tour operator, etc.) apply to
                your travel, so you must also agree to and understand those
                terms. Your interaction with any Travel Provider accessed
                through Our Website is at your own risk; BusinessClass.com does
                not bear any responsibility should anything go wrong with your
                booking or during your travel.
              </p>
              <p>
                The display on Our Website of a Travel Product or Travel
                Provider does not in any way imply, suggest, or constitute a
                recommendation by BusinessClass.com of that Travel Product or
                Travel Provider, or any sponsorship or approval of
                BusinessClass.com by such Travel Provider, or any affiliation
                between such Travel Provider and BusinessClass.com.
              </p>
              <p>
                BusinessClass.com hosts content, including prices, made
                available by or obtained from Travel Providers.
                BusinessClass.com is in no way responsible for the accuracy,
                timeliness, or completeness of such content. Since
                BusinessClass.com has no control over the Travel Products and
                does not verify the content uploaded by the Travel Providers, it
                is not possible for us to guarantee the prices displayed on Our
                Website. Prices change constantly and additional charges (e.g.,
                payment fees, service charges, checked-in luggage fees, local
                taxes and fees) may apply, so you should always check whether
                the price asked for a booking is the one you expected. Some
                Travel Products may also be sold in another currency than the
                one preset or chosen by you for the display of the search
                results. Our currency conversion is for informational purposes
                only and should not be relied upon as accurate or real-time;
                actual rates may vary, and your payment provider (e.g., your
                credit card company) may charge conversion fees and apply
                another date&apos;s currency rate.
              </p>

              <h3>4. Booking through BusinessClass.com</h3>
              <p>
                If you make a booking through Our Website for Travel Products,
                that booking is made with the Travel Provider named on the
                booking page, and Our Website only acts as a user interface.
                Accordingly, BusinessClass.com has no responsibility for the
                booking or the Travel Product because BusinessClass.com has no
                involvement in creating the description of the Travel Product,
                in defining the price and any fees, or in providing the Travel
                Products that you book. If you have any issues or disputes with
                your booking and/or the Travel Product, you agree to address and
                resolve these with the Travel Provider and not with us.
              </p>
              <h3>5. Intellectual Property</h3>
              <p>
                We, along with our corporate affiliates, the Travel Providers,
                and other licensors, own all of the text, images, software,
                trademarks, service marks, and other material contained on Our
                Website except User Content. You will not copy or transmit any
                of the material except if you are doing so for your personal,
                non-commercial use. All copyright, trademark, and other
                proprietary rights notices presented on Our Website must appear
                on all copies you print. Other non-BusinessClass.com product,
                service, or company designations on Our Website belong to those
                respective third parties and may be mentioned on Our Website for
                identification purposes only. You should contact the appropriate
                third party for more complete information regarding such
                designations and their registration status. Your use of and
                access to Our Website does not grant you any license or right to
                use any of the marks included on Our Website.
              </p>

              <h3>6. Use of Our Website</h3>
              <p>
                You may only use and register to become a user of Our Website if
                you are of sufficient legal age and can enter into binding
                contracts. If you become a registered user, you are responsible
                for maintaining the secrecy of your passwords, login, and
                account information. You will be responsible for all use of Our
                Website by you, anyone using your password and login information
                (with or without your permission), and anyone who you allow to
                access your travel itineraries. All information that you provide
                to us must be accurate and up-to-date. If any of your
                information changes, you must immediately update it. If you have
                reason to believe that your account is no longer secure (e.g.,
                loss, theft, or unauthorized disclosure or use of your
                information or computer or mobile device used to access Our
                Website), you must promptly change your personal information
                that is affected.
              </p>
              <p>
                If you decide to have messages or other communications from Our
                Website sent directly to your mobile device, you are solely
                responsible for keeping us updated with your current phone
                number, updating to the latest version of the mobile app, and
                for any charges incurred by receiving such messages. We will not
                be liable for information sent to a device that is associated
                with your outdated mobile phone number or using an outdated
                mobile app. If you install any software or enable any service
                that stores information from Our Website on any mobile device or
                computer, it is your responsibility, prior to transfer or
                disposal of such device, to remove your information or otherwise
                disable access to such software or service to prevent
                unauthorized access to your information or account.
              </p>
              <p>
                You may only use Our Website to search for legitimate travel
                deals; you may not use Our Website to make any false,
                fraudulent, or speculative reservations or any reservation in
                anticipation of demand. By using Our Website, you agree to
                comply with laws that apply to Norway and your own country,
                including laws that apply to exporting technical data.
              </p>
              <p>
                In addition, you agree not to do any of the following without
                prior express written permission from BusinessClass.com:
              </p>

              <ul className="list-disc mb-2">
                <li>
                  Access the site with any manual or automated process for any
                  purpose other than your personal use or for inclusion of
                  BusinessClass.com pages in a search index. Use of any
                  automated system or software to extract data from Our Website
                  (&quot;screen scraping&quot;), for commercial or non-commercial
                  purposes, is prohibited;
                </li>
                <li>
                  Violate the restrictions in any robot exclusion headers on Our
                  Website or bypass or circumvent other measures employed to
                  prevent or limit access to Our Website;
                </li>
                <li>
                  Deep-link to any portion of Our Website for any purpose;
                </li>
                <li>
                  Use any device, software, or routine that interferes or
                  attempts to interfere with the normal operation of Our Website
                  or take any action that imposes an unreasonable load on our
                  computer or network equipment;
                </li>
                <li>
                  Reproduce, duplicate, copy, sell, trade, resell, or exploit
                  Our Website;
                </li>
                <li>
                  Use any feature of Our Website for any purpose that is
                  unlawful, harmful, or otherwise objectionable or
                  inappropriate, as determined by us;
                </li>
                <li>
                  Post or distribute any material on Our Website that violates
                  the rights of any third party or applicable law;
                </li>
                <li>
                  Use Our Website to collect or store personal data about
                  others;
                </li>
                <li>Use Our Website for any commercial purpose; or</li>
                <li>
                  Transmit any ad or promotional materials on Our Website.
                </li>
              </ul>

              <p>
                We may, at our sole discretion, at any time and without advance
                notice or liability, suspend, terminate, or restrict your access
                to any or all components of Our Website. Furthermore, you can
                always delete your account.
              </p>
              <p>You further agree not to:</p>
              <ul className="list-disc mb-2">
                <li>
                  Circumvent, disable, or otherwise interfere with
                  security-related features of Our Website or features that
                  prevent or restrict the use or copying of any content or
                  enforce limitations on the use of Our Website or any content
                  on the Website;
                </li>
                <li>
                  Impersonate any person or entity, or otherwise misrepresent
                  your affiliation with a person or entity;
                </li>
                <li>
                  Intentionally or unintentionally violate or encourage others
                  to violate any applicable law, statute, ordinance, or
                  regulation;
                </li>
                <li>Provide false or deceptive information;</li>
                <li>Solicit personal information from anyone;</li>
                <li>
                  Delete, add, or otherwise change other people&apos;s User Content;
                </li>
                <li>
                  Remove or alter any copyright or other proprietary notices on
                  or in connection with any content on the Website;
                </li>
                <li>Publicly disparage anyone or any User Content;</li>
                <li>
                  Publish or post threats of violence, or promote or encourage
                  others to engage in violence or illegal activity.
                </li>
              </ul>
              <p>
                Please report abusive content to{' '}
                <a href="mailto:post@businessclass.com">
                  post@businessclass.com
                </a>{' '}
                if you see it.
              </p>

              <h3>7. Notify Us of Infringers</h3>
              <p>
                If you believe any of the content on Our Website violates your
                copyright, please notify us in writing. Our contact information
                is at the bottom of this section.
              </p>
              <p>
                In order for us to take action, you must do the following in
                your notice:
              </p>
              <ul className="list-disc mb-2">
                <li>Provide your physical or electronic signature;</li>
                <li>
                  Identify the copyrighted work that you believe is being
                  infringed;
                </li>
                <li>
                  Identify the item that you think is infringing your work and
                  include sufficient information, such as a URL or copy of the
                  webpage showing the URL, so that we can find it;
                </li>
                <li>
                  Provide us with a way to contact you, such as your address,
                  telephone number, or email;
                </li>
                <li>
                  Provide a statement that you believe in good faith that the
                  item you have identified as infringing is not authorized by
                  the copyright owner, its agent, or the law to be used in
                  connection with the services; and
                </li>
                <li>
                  {' '}
                  Provide a statement that the information you provide in your
                  notice is accurate, and that (under penalty of perjury) you
                  are authorized to act on behalf of the copyright owner whose
                  work is being infringed.
                </li>
              </ul>

              <p>Here is the contact information for copyright issues:</p>
              <p>
                <a href="mailto:post@businessclass.com">
                  post@businessclass.com
                </a>
              </p>
              <p>or</p>
              <p>
                BusinessClass.com
                <br />
                Fredriksborgveien 2
                <br />
                0286 Oslo
                <br />
                Norway
              </p>
              <p>
                Again, we cannot take action unless you give us all the required
                information.
              </p>

              <h3>8. Warranty Disclaimer</h3>
              <p>
                Our Website and all content and services provided on Our Website
                are provided on an &quot;as is&quot; and &quot;as available&quot; basis. Our content
                is largely generated in an automated fashion; errors can and do
                happen. We usually have many search results, but we are not
                comprehensive and do not display all available providers and
                offers. Accordingly, we do not always display the lowest
                available price. BusinessClass.com expressly disclaims to the
                fullest extent permissible all warranties of any kind, whether
                express or implied, including, but not limited to, any implied
                warranties of merchantability, fitness for a particular purpose,
                title, non-infringement, and security and accuracy, as well as
                all warranties arising by usage of trade, course of dealing, or
                course of performance.
              </p>

              <h3>9. Our Liability Is Limited</h3>
              <p>
                We (together with our officers, directors, employees,
                representatives, shareholders, affiliates, and providers), to
                the extent permitted by law, hereby expressly exclude any
                responsibility and liability for:
              </p>

              <ul className="list-disc mb-2">
                <li>
                  Any loss or damages to, or viruses that may infect, your
                  computer equipment or other property as the result of your
                  access to Our Website, your downloading of any content from
                  Our Website; or
                </li>
                <li>
                  Any injury; death; loss; claim; act of god; accident; delay;
                  or any direct, special, exemplary, punitive, indirect,
                  incidental or consequential damages of any kind (including
                  without limitation lost profits or lost savings), whether
                  based in contract, tort, strict liability or otherwise, that
                  arise out of or is in any way connected with:
                  <ul className="list-disc">
                    <li>Any use of Our Website, our content;</li>
                    <li>
                      Any failure or delay (including without limitation the use
                      of or inability to use any component of this Website for
                      reservations or booking); or
                    </li>
                    <li>
                      The performance or nonperformance by us or any Travel
                      Provider, even if we have been advised of the possibility
                      of damages to such parties or any other party. Some states
                      or countries do not allow this limitation of liability, so
                      the limitations above may not apply or apply only
                      partially to you.
                    </li>
                  </ul>
                </li>
              </ul>

              <h3>10. You Agree to Protect Us</h3>
              <p>
                Subject to these Terms, you will defend, indemnify and hold us
                and each of our officers, directors, employees, and agents
                harmless from and against any claim, cause of action, liability,
                expense, loss, or demand, including without limitation
                reasonable legal and accounting fees, arising out of, or in any
                way connected with your breach of these Terms or the agreements
                made part of these Terms by reference, your breach of any
                applicable law, and your use of or access to Our Website or the
                Intellectual Property.
              </p>

              <h3>11. Jurisdiction and Applicable Law</h3>
              <p>
                You agree that the Privacy Policy and Terms & Conditions of
                BusinessClass.com shall, save for mandatory local law, be
                governed and construed in accordance with the laws of Norway and
                be subject to the non-exclusive jurisdiction of the courts of
                Norway.
              </p>
              <h3>12. Links to Other Websites</h3>
              <p>
                Our Website may contain links to other websites that we do not
                operate or control and for which we are not responsible (&quot;Other
                Websites&quot;). We provide these links for your reference and
                convenience and do not endorse the contents of Other Websites
                and accept no responsibility for them or for any loss or damages
                that may arise from your use of them. You should refer to the
                separate terms of use, privacy policies, and other rules posted
                on Other Websites before you use them.
              </p>
              <h3>13. Air Carrier Rules</h3>
              <p>
                The circumvention of an air carrier&apos;s rules, including practices
                such as back-to-back ticketing (purchasing two or more tickets
                with overlapping travel dates in order to circumvent minimum
                stay requirements) and hidden-city ticketing (purchasing tickets
                including segments which the purchaser does not intend to use in
                order to circumvent an air carrier&apos;s pricing structure), is
                prohibited by many air carriers. The use of prohibited ticketing
                practices may result in the air carrier taking actions,
                including the cancellation of the ticket, denied boarding,
                revocation of frequent flier miles and other benefits,
                additional charges to the purchaser&apos;s credit card, additional
                charges collected at the airport, or future invoicing.
              </p>
              <h3>14. User Content</h3>
              <p>
                The websites and services contain reviews, travel guides, or
                other forums in which you can post content. If you use said
                interactive areas on the websites, you are solely responsible
                for the travel information and other content, including without
                limitation, any reviews, text, images, links, or videos that you
                upload, transmit, or share with us or others on or through the
                website (collectively, the &quot;User Content&quot;), and you represent
                and warrant that you are not transmitting or sharing User
                Content that you do not have permission to share. We do not
                guarantee any confidentiality with respect to the User Content
                and you understand that the User Content may be publicly
                displayed.
              </p>
              <p>
                By posting User Content to the Website, you represent that:{' '}
              </p>

              <ul className="list-disc mb-2">
                <li>You are the owner of the User Content; or</li>
                <li>
                  You have the right and license to use the User Content; or
                </li>
                <li>The User Content is in the public domain; or</li>
                <li>
                  To your knowledge, no one else claims ownership of, or
                  exclusive rights to, the User Content; and
                </li>
                <li>
                  You have the legal right to grant a license to us to use the
                  User Content.
                </li>
              </ul>

              <p>
                When you provide us with User Content, you own the content you
                create and share, and you also grant us a perpetual,
                transferable, irrevocable, sub-licensable, fully-paid, worldwide
                license to use, modify, reproduce, distribute, prepare
                derivative works of, publicly perform, and publicly display (in
                any form and on all mediums now known or hereafter devised) all
                User Content or other content provided to us. We can use the
                User Content in any format, channel, platform, or region with
                the right to localize the content into other languages. If
                uploaded or submitted to us, you further give us permission and
                the right to use your name, image, likeness, or other personal
                attributes.
              </p>
              <p>
                You authorize us to make copies as we deem necessary to
                facilitate the storage and assimilation of the User Content on
                the websites. By providing us User Content, you represent and
                warrant that the User Content you provide will not violate or in
                any way infringe upon the rights of third parties, including
                property, contractual, employment, trade secrets, proprietary
                information, and nondisclosure rights, or any intellectual
                property rights. You may remove your User Content from the
                website, but the license that you have granted will remain in
                effect.
              </p>
              <p>
                You understand that we do not control the User Content and will
                not be, in any way, responsible or liable for such User Content.
              </p>
              <p>
                We take no responsibility for any User Content posted, stored,
                uploaded, distributed, transmitted or otherwise published by any
                User, including yours, on Our Website. We assume no liability
                for any loss or damage to User Content, nor are we liable for
                any mistakes, defamation, slander, libel, omissions, falsehoods,
                obscenity, pornography or profanity User may encounter when
                viewing User Content. Although we have no obligation to screen,
                edit or monitor any User Content, we reserve the right, and have
                the sole and absolute discretion to remove, edit or screen,
                without notice, any such User Content from Our Website at any
                time and for any reason, including, but not limited to when we
                believe in good faith that such User Content is in breach of
                these Terms or otherwise believe the removal may be reasonably
                necessary to safeguard our rights or the rights of Users or to
                mitigate any risk of harm or liability to us or any User.
              </p>
              <p>
                Further, you expressly agree not to post, upload, transmit,
                distribute, store, create or otherwise publish any User Content
                on Our Website that:
              </p>
              <ul className="list-disc mb-2">
                <li>
                  Is unlawful, harmful, inappropriate, false, misleading,
                  libelous, defamatory, obscene, pornographic, indecent, lewd,
                  suggestive, harassing, promoting of harassment of a third
                  party, threatening, in violation of privacy or publicity
                  rights, abusive, inflammatory, offensive to the online
                  community (such as, without limitation, promotes racism,
                  bigotry, hatred or physical harm of any kind against any group
                  or individual), fraudulent or otherwise objectionable;
                </li>
                <li>
                  Would constitute, promote, encourage, provide instructions
                  for, or enable conduct of an illegal activity, criminal
                  offense, give rise to civil liability, violate the rights of
                  any third party in any country of the world, or that would
                  create liability or violate any local, state, national or
                  international law, such as, without limitation, providing
                  instructions or guides about attending or participating in
                  illegal activities such as human trafficking or creating
                  computer viruses;
                </li>
                <li>
                  May infringe any patent, trademark, trade secret, copyright,
                  proprietary right or any other intellectual property right of
                  any party;
                </li>
                <li>
                  Constitutes mass mailings, &quot;spamming&quot;, junk mail, pyramid
                  schemes, or chain letters;
                </li>
                <li>
                  Impersonates any person or entity or misrepresents your
                  affiliation with any person or entity;
                </li>
                <li>
                  Is private information of any third party, including but not
                  limited to, Social Security numbers, passwords, credit card
                  numbers, addresses, email addresses, phone numbers, and any
                  other information that enables a User to readily identify any
                  third party;
                </li>
                <li>Contains restricted or hidden content;</li>
                <li>
                  Includes or facilitates viruses, corrupt data or other
                  harmful, destructive or disruptive files;
                </li>
                <li>
                  Is unrelated to the topic or intended use of the area on Our
                  Website, in our sole discretion; or
                </li>
                <li>
                  In our sole discretion, restricts any other person from using
                  or enjoying Our Website.
                </li>
              </ul>

              <h3>15. Hacker Fare</h3>
              <p>
                In the case of a Hacker Fare, Our Website displays search
                results for a combination of Travel Products (e.g., separate
                flight and hotel results, or two one-way flights, instead of a
                round trip). If you use these search results, you will make two
                different bookings with different rules and policies (for
                example, for baggage fees, change fees, and refunds). Any
                changes made to one of your bookings will not affect the other
                booking (for example, if your initial flight is cancelled by
                either you or the airline, the other airline with which you
                booked your return flight is not obligated to issue a refund or
                change your itinerary). If you are traveling internationally,
                you may need to provide proof that you have a return flight at
                check-in and at immigration.
              </p>

              <h3>16. Member Club</h3>
              <h4>16.1 Membership Levels</h4>
              <p>
                BusinessClass.com offers a Member Club with two levels: Gold and
                Elite.
              </p>

              <p>
                <b>Gold Membership:</b> <br />
                To become a Gold member, you must subscribe to our newsletters
                and complete your profile information. This enables us to send
                you more relevant offers and deals based on your preferences and
                profile.
              </p>

              <p>
                <b>Elite Membership:</b> <br />
                Elite membership is a paid subscription that provides access to
                all Gold membership benefits plus exclusive access to flight
                tickets with deep discounts.
              </p>

              <h4>16.2 Membership Benefits</h4>
              <p>
                <b>Gold Membership Benefits:</b> <br />
                - Access to special offers and deals tailored to your
                preferences. <br />- Regular updates via our newsletters.
              </p>

              <p>
                <b>Elite Membership Benefits:</b> <br />
                - All benefits of the Gold membership. <br />- Access to
                exclusive flight tickets with significant discounts.
              </p>

              <h4>16.4 Membership Cancellation</h4>
              <p>
                - You may cancel your membership at any time by contacting our
                customer service. <br />- If you cancel an Elite membership, you
                will not be entitled to a refund for any remaining period of
                your subscription.
              </p>

              <h3>17. General Requirements</h3>
              <p>
                We may change the site and these Terms at any time, in our sole
                discretion and without notice to you. You are responsible for
                remaining knowledgeable about these Terms. Your continued use of
                the site constitutes your acceptance of any changes to these
                Terms and any changes will supersede all previous versions of
                the Terms. Unless otherwise specified herein, all changes to
                these Terms apply to all users, including those enrolled before
                the date the changes take effect. Furthermore, we may terminate
                this agreement with you under these Terms at any time by
                notifying you in writing (including by email) and/or, if you are
                a registered user, by cancelling your account and your access to
                your account.
              </p>
              <p>
                Nothing contained in these Terms will be deemed to constitute
                either party as the agent or representative of the other party,
                or both parties as joint venturers or partners for any purpose.
                You may not assign, delegate or transfer your rights or
                obligations under these Terms. We may assign our rights and
                duties under these Terms without such assignment being
                considered a change to the Terms and without notice to you,
                provided your rights under these Terms are not prejudiced.
              </p>
              <p>
                If we fail to act with respect to your breach or anyone else&apos;s
                breach on any occasion, we are not waiving our right to act with
                respect to future or similar breaches. If a court finds any of
                these Terms to be unenforceable or invalid, that Term will be
                enforced to the fullest extent permitted by applicable law and
                the other Terms will remain valid and enforceable. These Terms,
                together with those agreements made a part of these Terms by
                reference, make up the entire agreement between us relating to
                your use of Our Website, and replace any prior understandings or
                agreements (whether oral or written) regarding your use of Our
                Website.
              </p>
              <p>
                To the extent permitted by law, the laws of Norway, without
                regard to its conflict of laws rules, will govern these Terms,
                as well as your and our observance of them. If you take any
                legal action relating to your use of Our Website, or these
                Terms, or our services, you agree to file such action only in
                the courts located in Norway; if you are a consumer, the law may
                allow you to also bring proceedings in the courts for the place
                where you are domiciled. In any such action or any action we may
                initiate, the prevailing party will be entitled to recover all
                legal expenses incurred in connection with the action, including
                but not limited to costs, both taxable and non-taxable, and
                reasonable attorney fees. To the extent permitted by law, you
                agree that any disputes, claims and causes of action arising out
                of or connected with Our Website and/or these Terms, will be
                resolved individually, without resort to any form of class
                action.
              </p>

              <h3>18. Your Feedback</h3>
              <p>
                We encourage you to share your comments and questions with us
                here, but we may not be able to respond to all of them. Please
                note that we assume no responsibility for reviewing unsolicited
                ideas for our business (like product or advertising ideas) and
                will not incur any liability as a result of any similarities
                between those ideas and materials that may appear in future
                BusinessClass.com products or services. Also, please remember
                that you are responsible for whatever material you submit,
                including its reliability, originality, and copyright. Please do
                not reveal trade secrets or other confidential information in
                your messages. Any and all rights to materials submitted to us
                become the exclusive property of BusinessClass.com. Furthermore,
                by submitting Feedback, you are granting us an irrevocable,
                perpetual, non-exclusive, transferable, fully-paid, worldwide
                license (with the right to freely sublicense) to use, copy,
                modify, publicly perform, publicly display, reformat, translate,
                syndicate, republish, excerpt (in whole or in part) and
                distribute Feedback we receive from you for any purpose,
                including business, commercial, marketing, advertising, or
                otherwise.
              </p>

              <h3>19. Legal notices</h3>
              <p>
                Google Play and the Google Play logo are trademarks of Google
                LLC. Apple, the Apple logo, iPhone, and iPad are trademarks of
                Apple Inc., registered in the U.S. and other countries and
                regions. App Store is a service mark of Apple Inc.
              </p>

              <h3>20. Operator Information</h3>
              <p>Our Website is operated by:</p>
              <p>
                BusinessClass.com
                <br />
                Tjuvholmen Allé 3
                <br />
                0252 Oslo
                <br />
                Norway
              </p>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};
