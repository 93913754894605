import { createPopper } from '@popperjs/core';
import cn from 'classnames';
import React from 'react';
import { COUNTRIES } from '../../config';
import { changeCountry } from '../utils/changeCountry';
import styles from './CountrySelect.module.scss';
import arrowStyles from '../assets/Arrow.module.scss';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

export const CountrySelect = ({
  selectedCountry,
  transparentHeader,
  session,
  ...props
}) => {
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const dropdownHandlerRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(dropdownHandlerRef.current, popoverDropdownRef.current, {
      placement: 'bottom',
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  return (
    <div {...props} onMouseLeave={closeDropdownPopover}>
      <div
        className={cn('relative', 'flex items-center', 'w-full', 'font-libre')}
        ref={dropdownHandlerRef}
        onMouseEnter={openDropdownPopover}
      >
        <span
          className={cn(
            styles.flag32,
            `flag-32 flag-${selectedCountry?.toLowerCase()}`
          )}
        />
        <KeyboardArrowDownRoundedIcon
          className={cn({
            [arrowStyles.arrow]: transparentHeader,
            [arrowStyles.arrowBlackHeading]: !transparentHeader,
          })}
        />
      </div>

      <ul
        ref={popoverDropdownRef}
        className={cn(
          {
            block: dropdownPopoverShow,
            hidden: !dropdownPopoverShow,
          },
          'text-black',
          'float-left',
          'list-none',
          'text-left',
          'bg-white',
          'grid grid-cols-3',
          'px-3 py-2',
          styles.card
        )}
      >
        {Object.keys(COUNTRIES).map((country) => (
          <li key={country} className="relative">
            <div
              className="flex items-center h-8"
              onClick={() => {
                changeCountry(country, session);
                closeDropdownPopover();
              }}
            >
              <span
                className={cn(
                  styles.flag32,
                  `flag-32 flag-${country.toLowerCase()}`
                )}
              />
              {COUNTRIES[country]}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
