import { useWindowWidth } from '@react-hook/window-size';
import { createRef, useEffect, useState } from 'react';
import { setImageUrl } from '../../utils/setImageUrl';
import { ImageWithFallback } from '../ImageWithFallback/ImageWithFallback';
import { RatingBadge } from '../RatingBadge/RatingBadge';
import { StarRating } from '../StarRating/StarRating';
import styles from './Lists.module.scss';
import cn from 'classnames';

export const ReviewItem = ({ review, url, type, starRating, props }) => {
  const [imageHeight, setImageHeight] = useState(0);
  const width = useWindowWidth();
  const image = createRef();
  useEffect(() => {
    setImageHeight(image.current.offsetWidth / 1.78);
  }, [width]);
  let badgeType;

  switch (review.type) {
    case 'FULL':
      badgeType = 'badge-tested';
      break;

    case 'VERIFIED':
      badgeType = 'badge-verified-check';
      break;

    default:
      badgeType = 'badge-tested';
      break;
  }
  return (
    <a className={styles.a} href={url} {...props}>
      <div className={cn(styles.reviewContainer, 'font-libre')}>
      
        <div className={styles.reviewContentContainer}>
          <div className={cn(styles.header, 'font-libre')}>
            <p className="inline-block mr-4">{type} review</p>
            {starRating && (
              <span>
                <StarRating
                  small
                  rating={review.rating || review.stars * 2}
                  hideTextRating
                />
              </span>
            )}
          </div>
          <div className={cn(styles.ratingBadge, 'ml-auto')}>
            <div className="full-review">
              <RatingBadge version={badgeType} />
              {badgeType !== 'badge-verified-check' && <span className={'font-fragment'}>
                {review.rating || review.score}
              </span>}
            </div>
          </div>
          <span className={cn(styles.reviewTitle, 'font-fragment')}>
            {review.title}
            <br />
            {review.cabinClass}
          </span>
          <div
            className={cn(styles.reviewImage)}
            style={{ height: imageHeight }}
            ref={image}
          >
            <ImageWithFallback
              src={
                review.image?.includes('https')
                  ? review.image
                  : setImageUrl(review.image)
              }
              layout="fill"
              objectFit="cover"
              alt={review.title + ' image'}
              sizes="(max-width: 1024px) 100vw, 520px"

            />
          </div>
          <div
            className={cn(styles.reviewContent)}
            dangerouslySetInnerHTML={{ __html: review.content }}
          ></div>
        </div>
      </div>
    </a>
  );
};
