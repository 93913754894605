import { createTheme, ThemeProvider } from '@mui/material';
import cn from 'classnames';
import Image from 'next/image';
import React from 'react';
import styles from './CookiesPolicy.module.scss';
import { BCBreadcrumbs } from '../index';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00454A',
    },
    secondary: {
      main: '#202020',
    },
  },
  typography: {
    fontFamily: '"Libre Franklin", sans-serif',
    color: '#202020',
  },
});
export const CookiesPolicy = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className={styles.top}>
        <Image
          className={styles.topImage}
          src={`${process.env.mediaUrl}assets/cookie_front.jpeg`}
          layout="fill"
          objectFit={'cover'}
          objectPosition={'center 70%'}
        />
      </div>
      <div className={cn('container font-libre')}>
        <BCBreadcrumbs headerText="Cookies Policy"/>
        <div className={styles.content}>
          <h1 className={'font-fragment'}>
            COOKIES AND INTEREST-BASED ADVERTISING POLICY
          </h1>

          <p>for BusinessClass.com V02.04 Dated 10.07.2024</p>
          <h3>What are Cookies? </h3>
          <p>
            Cookies and similar tracking technologies, such as beacons, scripts,
            web beacons and tags (which together we refer to as “
            <strong>cookies</strong>”), are small bits of text, usually stored
            on a user&apos;s computer hard drive or within a browser. They
            enable a website to remember information about the user&apos;s visit
            to the website. On mobile websites and mobile applications, we use
            device IDs and / or Advertising IDs in a similar manner to which we
            use cookies.
          </p>
          <p>
            We and certain third-party partners (as described below) use cookies
            on our websites, our mobile applications and within emails or other
            communications we send to you (we refer to these together as our{' '}
            <strong>Sites</strong>).
          </p>
          <p>Please keep in mind that, without cookies, you may not have access to certain features on our Sites, including access to your profile</p>
          <h3 className=''>
            What Types of Cookies are Used on Our Sites and What Do We Use Them For?
          </h3>
          <p>
            We use first-party cookies, i.e., those that are used solely by us or by third parties who act only on our instructions or on our behalf. These types of cookies are used for our own analysis of our own Sites, or to provide functionality such as remembering your language preference.
          </p>
          <p>
            Other cookies on our Sites are used both by us for the purposes described in this policy and by third parties for purposes where those third parties may wish to combine information from our Sites with information from other sites to improve their services. For example, these types of cookies may be used for interest-based advertising purposes.
          </p>
          <p>
            The cookies used on our Sites, by either us or by third parties, can be “session” cookies, or “persistent” cookies. “Session” cookies are deleted automatically when you close your browser and are used for things like ensuring you are kept logged in as you navigate around our Sites. “Persistent” cookies remain stored on your device or browser even after you close it. These cookies are used, for example, to recognize you when you come back to our Sites.
          </p>
          <p>We divide the cookies we use into three categories:</p>
          <table cellPadding="10" border="1" className='table-auto'>
            <tbody>
              <tr>
                <th>Type of cookie</th>
                <th>Description of how we use this type of cookie</th>
                <th>What choices do you have?</th>
              </tr>
              <tr>
                <td>Required</td>
                <td>
                  <p>Essential for site functionality and security, such as maintaining your login status.</p>
                  <p>
                    - To provide a secure log-in to our Sites. <br />
                    - To keep you logged in so that you do not have to log in repeatedly to access different pages of the Sites.
                  </p>
                </td>
                <td>
                  Required cookies are necessary for our Sites to operate.
                </td>
              </tr>
              <tr>
                <td>Functional</td>
                <td>
                  <p>
                    Enhance user experience by remembering preferences and providing insights into site usage.
                  </p>
                  <p>
                    - To recognize you if you have visited previously, and remember your preferences from past visits, such as your country preference. <br />

                    - To track when you make purchases or bookings on websites operated by our Partners so that we can display your bookings with our group companies on your account page. <br />
                  </p>

                  <p>
                    Our Partners are: <br />
                    <p className='mb-0'><span>Kayak, </span><span><a href="https://www.google.com/url?q=https://www.kayak.com&amp;sa=D&amp;source=editors&amp;ust=1720183177914030&amp;usg=AOvVaw3jZZTAgydQJo7iB5Q5lcqM">Kayak</a></span><span>.</span></p>

                    <p className='mb-0'><span>ETraveli Group AB, </span><span><a href="https://www.google.com/url?q=https://www.etraveligroup.com/&amp;sa=D&amp;source=editors&amp;ust=1720183177914246&amp;usg=AOvVaw16Les9tyyK4B0yaii7LwKi">https://www.etraveligroup.com</a></span><span>.</span></p>

                    <p className='mb-0'><span>Amadeus Connect, </span><span><a href="https://www.google.com/url?q=https://amadeus.com&amp;sa=D&amp;source=editors&amp;ust=1720183177914406&amp;usg=AOvVaw3UNU-UiwJwBfVZ-XAFKbrk">Amadeus</a></span><span>&nbsp;.</span></p>

                    <p className='mb-0'><span>HotelsCombined, </span><span><a href="https://www.google.com/url?q=https://hotelscombined.com&amp;sa=D&amp;source=editors&amp;ust=1720183177914580&amp;usg=AOvVaw3Q1UjNARzR4je-C805oecM">HotelsCombined</a></span><span>.</span><span>&nbsp;</span></p>
                  </p>

                  <p>
                    - To improve our Sites and our offerings by helping us to understand how users use the Sites and to analyze trends which appear in that data. <br />

                    - To personalize your experience of our Sites, for example, by showing you offers on our homepage or in emails that are most relevant to you based on your previous visits to our Sites or those operated by our group companies or by ranking certain types of options (e.g., only non-stop flights) higher in your search results.
                  </p>
                </td>
                <td>
                  <p>
                    Where required by law, functional cookies will only be used on the Sites where you have given your consent to their use.
                  </p>
                  <p>
                    You can opt out of our use of Google Analytics by
                    downloading the browser add-on{' '}
                    <a
                      className="_kiA"
                      href="https://tools.google.com/dlpage/gaoptout"
                      rel="noreferrer noopener nofollow"
                    >
                      here
                    </a>
                    .
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Advertising
                </td>
                <td>
                  <p>
                    Used for personalized ads and analytics.
                  </p>
                  <p>
                    - To recognize you if you have used our services on another device. We combine information received from multiple devices to determine if you are using a new device, so that we can continue to provide you with a personalized experience on our Sites.
                  </p>
                  <p>
                    - To enable you to post to social media sites and other platforms, for example, to allow you to access a particular social media page to post a comment or a “like.”
                  </p>
                  <p>
                    - To collect information for use in advertising on our own Sites and on third party sites which you may visit. Such information is based on your preferences and your usage of our Sites.
                  </p>
                </td>
                <td>
                  <p>
                    Where required by law, advertising cookies will be used only
                    on the Sites where you have given your consent to their use.
                  </p>
                  <p>
                    See the section entitled “Your Cookie Choices” below to
                    learn more about how to withdraw your consent to advertising
                    cookies.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>

          <h3>Our Advertising Cookies </h3>
          <p>
            We work with third parties that place cookies on our Sites to
            provide their services, including:
          </p>
          <ol className="_im7 _kkE">
            <li>
              <strong>Ad Targeting</strong>. We work with third-party advertising companies to serve ads while you are visiting our Sites and to permit these companies to place and access their own cookies on your computer in the course of serving advertisements on our Sites. These companies may use information obtained through their cookies (which does not include your name, address, e-mail address, or telephone number) about your visits to our Sites and other websites, in combination with information about your purchases and interests from other online sites, to provide advertisements about goods and services of interest to you.
            </li>
            <li>
              <strong>Advertising Analytics/Measurement.</strong> We make use of analytics cookies as part of our online advertising campaigns to learn how users interact with our Sites after they have been shown an online advertisement, which may include advertisements on third-party websites. In particular, our Sites use Google Analytics 4, which are web analytics and analytics advertising services provided by Google, Inc., which use third-party cookies. We have enabled the IP address anonymization functionality so that the IP address of the user is shortened by Google. Our Sites also use the following Google Analytics Advertising features: Google Remarketing, Google Segments, Google Ads Manager, and Google Ads. These features use third-party cookies to collect information about our website traffic by tracking users across websites and across time and generating reports for us to better understand our website users and to create audiences for our advertisements based on such understanding.
            </li>
            <li>
              <strong>Remarketing Pixel Tags</strong>. We may share website usage information about visitors to our Sites with third-party advertising companies for the purpose of managing and targeting advertisements and for market research analysis on our Sites and other sites. For these purposes, we and our third-party advertising companies may place pixel tags (also called clear gifs) on some of the pages you visit on our Sites. We will then use the information that is collected using these pixel tags to serve you relevant advertisements when you are visiting other sites on the Internet. These advertisements may be based on your interests, for example, on the basis of specific searches you conducted on our Sites during earlier browsing sessions.
            </li>
            <li>
              <strong>Other Cookies:</strong> Our partners, who provide certain features on our Sites or display advertising based on your browsing activity, also use Local Storage such as HTML5, to collect and store content information and preferences. Please note that different web browsers offer their own management tools for removing HTML5 local storage.
            </li>
          </ol>
          <p>
            <strong>
              Mobile Devices, Anonymous Device Identifiers and Hashed IDs.
            </strong>{' '}
            We and/or third parties that we have engaged may collect and store a unique identifier matched to your mobile device, in order to deliver customized ads or content while you use applications or surf the internet, or to identify you in a unique manner across other devices or browsers. In order to customize these ads or content, we or a third party may collect de-identified usage data or other data about you which you have either voluntarily submitted to us, for example, your email address, or has been passively collected from you, such as your device identifier or IP address, where permitted. However, if we share your email address, we share it solely in hashed, non-human-readable form.
          </p>

          <h3>Your Cookie Choices </h3>
          <p>
            We provide you with choices over the use of cookies as described in
            this policy.
          </p>
          <p>
          When you first visit our Sites, where required by law, you will have the option to give your explicit consent to the advertising and functional cookies we use, or to refuse your consent to our use of those cookies by clicking on the [X] within that disclosure.
          </p>
          <p>
            <strong>Browsers.</strong> Most browsers accept cookies by default.
            You may be able to change the settings to have your browser refuse
            certain cookies or notify you before accepting cookies. To do so,
            please follow the instructions provided by your browser which are
            usually located within the &quot;Help&quot;, &quot;Tools&quot; or
            &quot;Edit&quot; facility. Some third parties also provide the
            ability to refuse their cookies directly by clicking on an opt-out
            link, and we have tried to indicate where this is possible in the
            table above.
          </p>
          <p>
            You can also find more information about cookies, including how to
            see what cookies have been set on your device and how to manage and
            delete them, at{' '}
            <a className="_kiA" href="https://www.allaboutcookies.org" rel="noreferrer noopener nofollow">
              www.allaboutcookies.org
            </a>{' '}
            and{' '}
            <a className="_kiA" href="https://www.youronlinechoices.eu" rel="noreferrer noopener nofollow">
              www.youronlinechoices.eu
            </a>
            .
          </p>
          <p>
            <strong>Mobile Devices.</strong> IIf you no longer wish to receive interest-based advertising on your mobile applications, please refer to your device’s operating system settings and follow instructions below.
          </p>
          <ol>
            <li>
              iOS users: To use the &quot;Limit Ad-Tracking&quot; option, follow
              the instructions provided by Apple here:{' '}
              <a
                className="_kiA"
                href="https://support.apple.com/en-us/HT202074"
                rel="noreferrer noopener nofollow"
              >
                Apple Support Center
              </a>
            </li>
            <li>
              Android Users: To use the &quot;opt-out of interest-based
              advertising&quot; option, follow the instructions provided by
              Google here:{' '}
              <a
                className="_kiA"
                href="https://support.google.com/googleplay/answer/3405269"
                rel="noreferrer noopener nofollow"
              >
                Google Play Help
              </a>
            </li>
            <li>
              You may also consider downloading the <a href="https://youradchoices.com/appchoices" target="_blank" rel="noopener noreferrer nofollow">DAA AppChoices Mobile App</a> to
              manage online behavioral advertising technology.
            </li>
          </ol>
          <p>
            <br />
            If you no longer wish to receive interest-based advertising on your mobile device browser, you can follow the steps in relation to Browsers outlined above.
          </p>
          <p>
            <strong>Do-Not-Track Signals.</strong> We currently do not employ
            technology that recognizes “do-not-track” signals from your browser.
          </p>
          <p>
            Please keep in mind that, without cookies, you may not have access to certain features on our Sites, including access to your profile
          </p>
          <p>
            Please also refer to our Privacy Policy on{' '}
            <a className="_kiA" href="https://www.businessclass.com/view/privacy_policy">
            https://www.businessclass.com/view/privacy_policy
            </a>{' '} and Term & Conditions for use on <a href="https://www.businessclass.com/view/terms_conditions" target="_blank">https://www.businessclass.com/view/terms_conditions</a> 
          </p>
        </div>
      </div>
    </ThemeProvider>
  );
};
