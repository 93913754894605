import styles from './AuthorPopup.module.scss';
import Image from 'next/image';
import cn from 'classnames';
import moment from 'moment';
import { Popper } from '@mui/material';
import React, { useState } from 'react';
import Link from 'next/link';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export const AuthorPopup = ({ userBio, modifiedDate, isReview = true }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleHover = (event) => {
    if (event) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  return (
    <div
      className={'flex items-center mb-6'}
      onMouseEnter={(e) => handleHover(e)}
      onMouseLeave={() => handleHover(null)}
    >
      {userBio.image && (
        <div className={styles.userBioImageWrapper}>
          <Image
            src={
              userBio.image?.startsWith('/')
                ? process.env.mediaUrl + userBio.image?.replace('/', '')
                : process.env.mediaUrl + userBio.image
            }
            layout={'fill'}
            objectFit={'cover'}
          />
        </div>
      )}
      <div>
        <div className={cn(styles.subtitle, 'font-libre')}>
          {isReview ? 'BusinessClass.com review' : ''} by{' '}
          {userBio.textId ? (
            <a href={'/authors/' + userBio.textId}>
              {userBio.user?.firstName} {userBio.user?.lastName}
            </a>
          ) : (
            <>
              {userBio.user?.firstName} {userBio.user?.lastName}
            </>
          )}
        </div>
        {modifiedDate && (
          <div className={cn(styles.subtitleMb, 'font-libre')}>
            Updated {moment(modifiedDate).format('D. MMM yyyy')}
          </div>
        )}
      </div>
      <Popper
        placement={'bottom-start'}
        id={id}
        open={open && !!userBio.content}
        anchorEl={anchorEl}
        style={{ zIndex: 3 }}
      >
        <div
          className={cn(styles.authorPopup, 'font-libre')}
          onMouseEnter={() => handleHover({ currentTarget: anchorEl })}
        >
          <div className={styles.userBioImageLargeWrapper}>
            <Image
              src={
                userBio.image?.startsWith('/')
                  ? process.env.mediaUrl + userBio.image?.replace('/', '')
                  : process.env.mediaUrl + userBio.image
              }
              layout={'fill'}
              objectFit={'cover'}
            />
          </div>
          <div className={styles.authorPopupContent}>
            <div className={styles.authorPopupIcon}>
              <AccountCircleIcon fontSize={'large'} />
            </div>
            <div className={styles.authorPopupOverline}>About the author</div>
            <div className={cn(styles.authorPopupName, 'font-fragment')}>
              {userBio.user?.firstName} {userBio.user?.lastName}
            </div>
            <div className={styles.authorPopupText}>{userBio.content}</div>
            <div className={styles.authorPopupReadMore}>
              {userBio.textId && (
                <Link href={'/authors/' + userBio.textId}>Read more</Link>
              )}
            </div>
          </div>
        </div>
      </Popper>
    </div>
  );
};
