import { createTheme, ThemeProvider } from '@mui/material';
import cn from 'classnames';
import Image from 'next/image';
import React from 'react';
import styles from './PrivacyPolicy.module.scss';
import { BCBreadcrumbs } from '../index';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00454A',
    },
    secondary: {
      main: '#202020',
    },
  },
  typography: {
    fontFamily: '"Libre Franklin", sans-serif',
    color: '#202020',
  },
});
export const PrivacyPolicy = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className={styles.top}>
        <Image
          className={styles.topImage}
          src={`${process.env.mediaUrl}assets/cookie_front.jpeg`}
          layout="fill"
          objectFit={'cover'}
          objectPosition={'center 70%'}
        />
      </div>
      <div className={cn('container font-libre')}>
        <BCBreadcrumbs headerText="Privacy Policy" />
        <div className={styles.content}>
          <div className="row section message">
            <div className="column large-16 large-centered">
              <h1 className={'font-fragment'}>Privacy Policy</h1>
              <p>for BusinessClass.com V02.01 Dated 10.07.2024</p>
              <p>
                This Privacy Policy describes how BusinessClass.com and its
                subsidiaries, (collectively, &quot;BusinessClass.com,&quot;
                &quot;we&quot;, &quot;our&quot; or &quot;us&quot;) collect, use,
                process and share personal information. This Policy applies to
                visitors and users (individually, &quot;you&quot;) of
                BusinessClass.com&quot;s websites (including but not limited to
                BusinessClass.com and AwardFlights.com), applications, social
                media accounts, and other services (collectively, our
                &quot;Services&quot;). Please note that your use of our Services
                is additionally subject to the BusinessClass.com Terms and
                Conditions.
                <a
                  href="https://www.businessclass.com/view/terms_conditions"
                  target="_blank"
                >
                  https://www.businessclass.com/view/terms_conditions
                </a>
              </p>
              {/* <p>
                BusinessClass.com connects travelers with travel services and
                products offered by third parties, such as flights,
                accommodations and more. This involves sharing personal
                information with third parties, including online travel
                agencies, hotels, airlines, and others (collectively,
                &quot;travel partners&quot;).
              </p>
              <p>
                For more information about the information we share with third
                parties and the recipients of such information, please refer to
                the How We Share Your Information section of this Policy. Please
                note that this Policy does not otherwise cover the use of
                personal information by third parties or their services, and we
                do not take responsibility for their privacy practices.
              </p>
              <p>
                You may have certain rights or choices that relate to your
                personal information, including choices about how we share your
                personal information with others. For more information, please
                refer to the Your Choices and Rights section of this Policy.
              </p> */}
              <h3>Overview</h3>
              <table>
                <tbody>
                  <tr>
                    <td width="20%"> Information We Collect and Use</td>
                    <td>
                      When you use or visit our Services, we collect information
                      directly from you, generate information about you, and
                      obtain personal information from third parties.
                    </td>
                  </tr>
                  <tr>
                    <td>How We Use Your Information</td>
                    <td>
                      We use your information to provide our Services, tailor
                      your experience, show you bookings and transactions made
                      with our travel partners, send you marketing
                      communications, provide you with more relevant advertising
                      and offers, and for other purposes described below.
                    </td>
                  </tr>
                  <tr>
                    <td>How We Share Your Information</td>
                    <td>
                      We share your information with travel partners, other
                      business partners, social networking services, third-party
                      services or applications you use to log into your
                      BusinessClass.com account, service providers, and others
                      as described below.
                    </td>
                  </tr>
                  <tr>
                    <td>How We Store and Protect Your Information</td>
                    <td>
                      We maintain technical, administrative, and physical
                      security measures to protect your information.
                    </td>
                  </tr>
                  <tr>
                    <td>Cookies</td>
                    <td>
                      We use cookies and similar technologies to help provide
                      our Services, offer you a more personalized user
                      experience, and market our and third parties&apos; products and
                      services to you.
                    </td>
                  </tr>
                  <tr>
                    <td>Your Choices and Rights</td>
                    <td>
                      You have choices about your personal information and may
                      have rights to opt out of receiving marketing
                      communications, change your communication preferences,
                      access and update your information, and exercise choices
                      regarding cookies.
                    </td>
                  </tr>
                  <tr>
                    <td>International Transfers of Information</td>
                    <td>
                      Information about you may be transferred to or accessed by
                      entities located in countries outside of your home
                      country.
                    </td>
                  </tr>
                  <tr>
                    <td>Links to Other Websites</td>
                    <td>
                      Our Services may contain links to other websites or
                      services that are not owned or controlled by
                      BusinessClass.com.
                    </td>
                  </tr>
                  <tr>
                    <td>Children</td>
                    <td>
                      Our Services are not directed at or intended for use by
                      children, nor do we knowingly collect information from
                      children under 18 years of age.
                    </td>
                  </tr>
                  <tr>
                    <td>Changes to This Policy</td>
                    <td>
                      We update this Policy from time to time, and changes will
                      be effective when posted.
                    </td>
                  </tr>
                  <tr>
                    <td>How to Contact Us</td>
                    <td>
                      If you have any questions about this Policy or the way
                      your personal information has been used, please contact
                      us.
                    </td>
                  </tr>
                </tbody>
              </table>
              <h3>Information We Collect and Use</h3>
              <h3>Personal Information We Collect Directly From You</h3>
              <p>
                When you visit or use our Services, we collect the following
                categories of personal information directly from you:
              </p>
              <ul className="list-disc mb-2">
                <li>
                  Personal details (such as your name, age, birthday, gender)
                </li>
                <li>
                  Contact information (such as email address, address, phone
                  number)
                </li>
                <li>
                  Booking information (such as traveler’s name, frequent flyer
                  details, passport number, country of citizenship, booking
                  reference number, itinerary details, meal preferences, luggage
                  information, and layover information)
                </li>
                <li>
                  Account information (such as login credentials, including
                  email address and password, and account settings)
                </li>
                <li>
                  Social media data (if you link your BusinessClass.com account
                  with a social media account, we may collect personal
                  information such as name, age, gender, photograph, and other
                  personal information relating to your social media account)
                </li>
                <li>
                  Billing information (such as credit, debit, or other payment
                  card information and billing address)
                </li>
                <li>
                  Your contacts (such as contact information of people you add
                  to or notify of your reservations or itineraries through our
                  Services)
                </li>
                <li>
                  Your preferences (such as home airport, seating preferences,
                  meal preferences, communication preferences, and other
                  preference information you provide)
                </li>
                <li>
                  Reviews you submit (including any screen name you publish
                  under or any personal information you include about yourself
                  or others in such review)
                </li>
                <li>
                  Content you publish (including travel recommendations and any
                  personal information you include about yourself or others in
                  content you publish)
                </li>
                <li>
                  Photos of you (such as when you add a photo of yourself to
                  your profile or upload photos to a review)
                </li>
                <li>
                  Communications you send us (such as questions, conversations,
                  complaints, or other information submitted to our support
                  team)
                </li>
                <li>
                  Promotion information (if you participate in a contest,
                  sweepstakes, or similar campaign, we collect information
                  provided in relation to such activity, such as photos, images,
                  captions, or other content)
                </li>
                <li>
                  Other information you may provide (including other information
                  provided about yourself or others through our Services or
                  accessed via third-party platforms)
                </li>
              </ul>

              <h3>Personal Information Generated By Us</h3>
              <p>
                As you use our Services, we generate certain personal
                information about you, including through automatic data
                collection and by inferences based on the information we collect
                about you and your activity. This includes:
              </p>
              <ul className="list-disc mb-2">
                <li>
                  Device information (such as your mobile device, computer, or
                  tablet, including information about your hardware and
                  software, device configuration, and nearby networks)
                </li>
                <li>
                  Usage and performance information (such as your searches,
                  statistics about travel itineraries you share, how you
                  interact with features of our Services, access times,
                  referring URL, and performance of our Services)
                </li>
                <li>
                  Location information (such as approximate location
                  information, or with your consent, precise geographic location
                  data from your mobile device)
                </li>
                <li>
                  Inferences about you (such as preferences and interests to
                  provide and personalize our Services and tailor advertising)
                </li>
              </ul>
              <h3>Personal Information We Obtain from Third Parties.</h3>
              <p>
                We may also receive certain categories of personal information
                from third parties, such as:
              </p>
              <ul className="list-disc mb-2">
                <li> Your name</li>
                <li>Email address or other contact information</li>
                <li>
                  Social media data, if you connect to our Services using a
                  social media third-party platform
                </li>
                <li>
                  Browsing, purchase, usage, and advertising details, including
                  information about restaurant or travel accommodations and
                  bookings made through travel partners
                </li>
              </ul>
              <h3>How We Use Your Information</h3>
              <p>
                We use your personal information for the following purposes
                (&quot;Purposes&quot;):
              </p>
              <ul className="list-disc mb-2">
                <li>
                  To provide the Services, including processing bookings,
                  facilitating travel arrangements, and providing customer
                  support
                </li>
                <li>
                  To authenticate your account credentials and identify you
                </li>
                <li>
                  To communicate with you about your account or use of our
                  Services
                </li>
                <li>To send you marketing communications</li>
                <li>
                  To operate and improve our Services and develop new products
                  and services
                </li>
                <li>To process and deliver contest entries and rewards</li>
                <li>To process your payment information</li>
                <li>To provide services and information to travel partners</li>
                <li>To tailor your experience with our Services</li>
                <li>
                  To provide you more relevant advertising on and off our
                  Services
                </li>
                <li>
                  To protect against, investigate, and deter fraudulent,
                  unauthorized, or illegal activity
                </li>
                <li>
                  To comply with our policies, procedures, and legal obligations
                </li>
              </ul>

              <h3>How We Share Your Information</h3>
              <p>
                We disclose the personal information we collect (or otherwise
                generate or obtain) as follows:
              </p>
              <ul className="list-disc mb-2">
                <li>
                  With our travel partners to provide the Services and for their
                  own purposes
                </li>
                <li>
                  With other business partners for their own marketing purposes
                </li>
                <li>
                  With social networking services to connect and share your
                  information
                </li>
                <li>
                  With third-party services or applications you use to log into
                  your account
                </li>
                <li>To process payments</li>
                <li>
                  With other service providers who perform services on our
                  behalf
                </li>
                <li>In the event of a corporate transaction</li>
                <li>To protect rights and property</li>
                <li>To comply with legal obligations</li>
                <li>Aggregate information</li>
                <li>At your request</li>
              </ul>

              <p>
                Our Partners are: <br />
                <p className="mb-0">
                  <span>Kayak, </span>
                  <span>
                    <a href="https://www.google.com/url?q=https://www.kayak.com&amp;sa=D&amp;source=editors&amp;ust=1720183177914030&amp;usg=AOvVaw3jZZTAgydQJo7iB5Q5lcqM">
                      Kayak
                    </a>
                  </span>
                  <span>.</span>
                </p>
                <p className="mb-0">
                  <span>ETraveli Group AB, </span>
                  <span>
                    <a href="https://www.google.com/url?q=https://www.etraveligroup.com/&amp;sa=D&amp;source=editors&amp;ust=1720183177914246&amp;usg=AOvVaw16Les9tyyK4B0yaii7LwKi">
                      https://www.etraveligroup.com
                    </a>
                  </span>
                  <span>.</span>
                </p>
                <p className="mb-0">
                  <span>Amadeus Connect, </span>
                  <span>
                    <a href="https://www.google.com/url?q=https://amadeus.com&amp;sa=D&amp;source=editors&amp;ust=1720183177914406&amp;usg=AOvVaw3UNU-UiwJwBfVZ-XAFKbrk">
                      Amadeus
                    </a>
                  </span>
                  <span>&nbsp;.</span>
                </p>
                <p className="mb-0">
                  <span>HotelsCombined, </span>
                  <span>
                    <a href="https://www.google.com/url?q=https://hotelscombined.com&amp;sa=D&amp;source=editors&amp;ust=1720183177914580&amp;usg=AOvVaw3Q1UjNARzR4je-C805oecM">
                      HotelsCombined
                    </a>
                  </span>
                  <span>.</span>
                  <span>&nbsp;</span>
                </p>
              </p>

              <h3>Membership Club</h3>

              <h4>Membership Levels</h4>
              <p>
                BusinessClass.com offers a Member Club with two levels: Gold and
                Elite.
              </p>

              <h4>Gold Membership:</h4>
              <p>
                - To become a Gold member, you must subscribe to our newsletters
                and complete your profile information. This enables us to send
                you more relevant offers and deals based on your preferences and
                profile.
              </p>

              <h4>Elite Membership:</h4>
              <p>
                - Elite membership is a paid subscription that provides access
                to all Gold membership benefits plus exclusive access to flight
                tickets with deep discounts.
              </p>

              <h4>Membership Benefits</h4>

              <h4>Gold Membership Benefits:</h4>
              <p>
                - Access to special offers and deals tailored to your
                preferences <br />- Regular updates via our newsletters
              </p>

              <h4>Elite Membership Benefits:</h4>
              <p>
                - All benefits of the Gold membership <br />- Access to
                exclusive flight tickets with significant discounts
              </p>

              <h4>Membership Terms</h4>
              <p>
                - Membership in the Member Club is subject to these Terms and
                Conditions and any additional terms and conditions that may
                apply to specific benefits or offers. <br />
                - Elite membership requires payment of a subscription fee. The
                fee amount, payment schedule, and payment methods will be
                specified at the time of subscription. <br />
                - Membership benefits are personal and non-transferable. You
                must not share your membership details or benefits with any
                third party. <br />
              </p>

              <h4>Membership Cancellation</h4>
              <p>
                - You may cancel your membership at any time by contacting our
                customer service. <br />
                - If you cancel an Elite membership, you will not be entitled to
                a refund for any remaining period of your subscription. <br />
              </p>

              {/* <h3>Electronic Communications</h3>
              <p>
                Consistent with the above Purposes and as permitted by
                applicable law, we may communicate with you via electronic
                messages, including email, text message, or mobile push
                notification to:
              </p>
              <ul>
                <li>
                  Send you information relating to our products and Services.
                  This may include booking confirmations and updates, receipts,
                  technical notices, updates, security alerts, and support and
                  administrative messages.
                </li>
                <li>
                  Send you marketing communications. In other situations,
                  subject to the Your Choices and Rights section below and
                  applicable law, we may communicate with you about contests,
                  offers, promotions, rewards, upcoming events, and other news
                  about products and Services offered by us, our travel
                  partners, and other business partners.
                </li>
              </ul>
              <p>
                With your consent, where required, we may contact you at the
                mobile phone number that you provide to us by way of direct dial
                calls, autodialed and prerecorded message calls, and text
                messages in connection with the above Purposes.
              </p>
              <h3>Our Role as Data Controller</h3>
              <p>
                For purposes of European Union law and similar data protection
                regimes, we generally act as a data controller, meaning we
                determine the purposes and means of processing your personal
                information. Our travel partners to whom we may connect you to
                make reservations are also data controllers. To learn more about
                how a travel partner may use your personal information, you
                should review its privacy notice.
              </p>
              <p>
                Processing Bases and Consequences. When we process your personal
                information, we rely on the following legal bases:
              </p>
              <ul>
                <li>
                  Performance of the contract we have with you (for example, if
                  you use our Services to book a flight, we will process your
                  information in order to allow the relevant airline partner to
                  complete and administer that booking).
                </li>
                <li>
                  Compliance with legal obligations to which we are subject
                  (such as when we are obliged to comply with lawful requests
                  from competent authorities such as law enforcement).
                </li>
                <li>
                  To serve our legitimate interests (such as tailoring your
                  experience with our Services, carrying out online advertising,
                  and for fraud detection), provided that such processing does
                  not outweigh your rights and freedoms. The processing must
                  also be pursuant to other applicable legal bases for data
                  processing, especially provisions set out under local law.
                </li>
              </ul>
              <p>
                When we use personal information to meet our legitimate
                interests, we take steps to ensure that your rights to your
                personal information are not infringed. You can contact us using
                the details set out in the How to Contact Us section below for
                more information about the steps we take to ensure these rights
                are not infringed. You also have the right to object to such
                processing as described in the Your Choices and Rights section
                below.
              </p>
              <ul>
                <li>
                  Consent
                  <br />
                  To the extent that a legal ground described above would not
                  apply to processing of your personal information by us, we
                  will seek your consent for such specific purpose in accordance
                  with applicable law (such as sending direct marketing messages
                  by electronic means, like email, without an exception from the
                  requirement to obtain consent).
                </li>
              </ul>
              <h3>Aggregate Information</h3>
              <p>
                We aggregate personal information collected directly from you,
                information generated about you by us, and information obtained
                from third parties (with your consent, where required) with
                personal information collected about other users in order to
                produce general statistics that cannot be linked to you or any
                other specific user. Information that has been aggregated and
                de-identified is no longer considered &quot;personal
                information&quot; and may be subsequently used for any purpose.
              </p>
              <h3>Anonymized Information</h3>
              <p>
                We may process information that cannot be linked to you or any
                other specific user using any means available to us, either
                because it was collected anonymously or has been subsequently
                anonymized. Information that is anonymous or has been anonymized
                is no longer considered &quot;personal information&quot; and may
                be subsequently used for any purpose.
              </p> */}

              <h3>How We Store and Protect Your Information</h3>
              <p>
                We maintain technical, administrative, and physical security
                measures to protect your information from loss, misuse,
                unauthorized access, disclosure, alteration, and destruction.
                When your payment information is transmitted through our
                Services, it will be protected by cryptographic protocols. We
                only store your payment information if you choose to save that
                information to your account. However, we cannot guarantee the
                security of your information, and you should not share your
                username, password, or other security information with anyone.
              </p>
              <h3>Retention</h3>
              <p>
                We may retain your personal information for as long as your
                account is active and for a period thereafter to allow you to
                re-activate your account without loss of information. We may
                also retain your information as necessary to comply with legal
                obligations, protect our rights, resolve disputes, and enforce
                our contracts.
              </p>

              <h3>Cookies</h3>
              <p>
                We use cookies and similar technologies to collect information
                about your usage and activity. Third parties may also use
                cookies on our Sites. See our Cookies and Interest-Based
                Advertising Policy for more details.{' '}
                <a
                  href="https://www.businessclass.com/view/cookies_policy"
                  target="_blank"
                >
                  https://www.businessclass.com/view/cookies_policy
                </a>
              </p>

              <h3>Your Choices and Rights</h3>
              <p>
                You have choices about your personal information, including the
                ability to opt out of marketing communications, change
                communication preferences, access and update your information,
                and exercise choices regarding cookies.
              </p>

              <h4>Email</h4>
              <p>
                To opt out of marketing emails, click on the &quot;unsubscribe&quot; link
                in the email or adjust your account settings.
              </p>
              <h4>Push Notifications</h4>
              <p>
                Use your mobile device settings to enable or turn off push
                notifications.
              </p>
              <h4>Text Messages</h4>
              <p>
                Reply STOP to opt out of text messages or adjust your account
                settings.
              </p>
              <h4>Cookies and Interest-Based Advertising</h4>
              <p>
                See our Cookies and Interest-Based Advertising Policy for
                details on how to exercise choices regarding cookies and other
                online tracking.
              </p>
              <h4>Application Location</h4>
              <p>Change your device settings to turn off location services.</p>
              <h4>Sharing with Third Parties</h4>
              <p>
                Opt out of sharing your information with third parties for their
                marketing purposes by changing your account preferences or
                contacting us.
              </p>
              <h4>Access and Correction</h4>
              <p>
                If you have an account, you can access and update your
                information through your account settings. Contact us if you
                wish to exercise legal rights related to your personal
                information.
              </p>

              <h3>International Transfers of Information</h3>
              <p>
                Information about you may be transferred to or accessed by
                entities in countries outside of your home country. We have put
                in place safeguards to protect your information during
                international transfers.
              </p>

              <h3>Links to Other Websites</h3>
              <p>
                Our Services may contain links to other websites or services
                that are not owned or controlled by BusinessClass.com. Your use
                of third-party websites is at your own risk, and you should read
                their privacy policies.
              </p>

              <h3>Children</h3>
              <p>
                Our Services are not directed at or intended for use by
                children. We do not knowingly collect information from children
                under 18 years of age. If you become aware that a child has
                provided us with information, please contact us.
              </p>

              <h3>Changes to This Policy</h3>
              <p>
                We update this Privacy Policy from time to time. Changes will be
                effective when posted. If changes materially impact previously
                collected personal information, we will provide notice and
                obtain consent as required by law.
              </p>

              <h3>How to Contact Us</h3>

              <p>
                If you have any questions about this Policy or how your personal
                information has been used, please contact us at: <br />
                <a href="mailto:post@businessclass.com">
                  post@businessclass.com
                </a>{' '}
                <br />
                or <br />
                <i> 
                  BusinessClass.com <br />
                  Attn: Data Protection Officer <br />
                  Fredriksborgveien 2 <br />
                  0286 Oslo <br />
                  Norway <br />
                </i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};
