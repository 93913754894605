import { Autocomplete, CircularProgress, createTheme, debounce, Paper, TextField, ThemeProvider } from '@mui/material';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { httpGet } from '../../utils/httpClient';
import styles from './AirlineAutocomplete.module.scss';


export const AirlineAutocomplete = ({
                                      label,
                                      placeholder,
                                      icon,
                                      name,
                                      setFieldValue,
                                      error,
                                      value,
                                      primaryColor = '#00454A'
                                    }) => {
  const theme = createTheme({
    shape: {
      borderRadius: 12
    },
    palette: {
      primary: {
        main: primaryColor
      }
    },
    typography: {
      fontFamily: '"Libre Franklin", sans-serif'
    }
  });
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (value) {
      setOpen(false);
    }
  }, [value]);
  const setInputValue = (value) => {
    setLoading(true);
    httpGet(`/api/airline?name=${value}`).then((response) => {
      response.json().then((result) => {
        if (!result?.empty) {
          setOptions(result.content);
        }
        setLoading(false);
      });
    });
  };
  const setValue = (newValue) => {
    setFieldValue(name, newValue);
  };

  return (
    <Autocomplete
      disablePortal={false}
      value={value || ''}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      isOptionEqualToValue={(evt) => evt.name}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      filterOptions={(x) => x}
      onInputChange={debounce((event, newInputValue, reason) => {
        if (newInputValue.length && reason === 'input') {
          setOpen(true);
          setInputValue(newInputValue);
        }
      }, 300)}
      PaperComponent={({ children }) => (
        <Paper className={cn(styles.option, 'font-libre')}>
          {children}
        </Paper>
      )}
      options={options}
      onBlur={() => setOptions([])}
      getOptionLabel={(option) => {
        return option ? `${option.name} (${
          option.code
        })` : '';
      }}
      freeSolo
      fullWidth
      renderInput={(params) => (
        <ThemeProvider theme={theme}>
          <TextField
            {...params}
            error={error}
            placeholder={placeholder}
            label={label}
            InputProps={{
              ...params.InputProps,
              startAdornment: <>{icon}</>,
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                  {loading ? (<CircularProgress color="inherit" size={20} />
                  ) : null}
                </>
              )
            }}
          />
        </ThemeProvider>
      )}
    />
  );
};
