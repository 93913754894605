import styles from './AuthorBlock.module.scss';
import cn from 'classnames';
import Image from 'next/image';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Link from 'next/link';
import React from 'react';

export const AuthorBlock = ({ userBio }) => {
  const authorContent = (
    <div className={cn(styles.blockWrapper)}>
      {userBio.image && (
        <div className={styles.userBioImageWrapper}>
          <Image
            src={
              userBio.image?.startsWith('/')
                ? process.env.mediaUrl + userBio.image?.replace('/', '')
                : process.env.mediaUrl + userBio.image
            }
            layout={'fill'}
            objectFit={'cover'}
          />
        </div>
      )}
      <div className={styles.authorBlockContent}>
        <div className={styles.authorBlockIcon}>
          <AccountCircleIcon fontSize={'large'} />
        </div>
        <div className={cn(styles.authorBlockOverline, 'font-libre')}>
          About the author
        </div>
        <div className={cn(styles.authorBlockName, 'font-fragment')}>
          {userBio.user?.firstName} {userBio.user?.lastName}
        </div>
        <div
          className={cn(
            styles.authorBlockText,
            'font-libre lg:text-left text-center my-2'
          )}
        >
          {userBio.jobTitle}
        </div>
        <div className={cn(styles.authorBlockText, 'font-libre')}>
          {userBio.content}
        </div>
        <div className={cn(styles.authorBlockReadMore, 'font-libre')}>
          Read more
        </div>
      </div>
    </div>
  );
  return userBio.textId ? (
    <Link href={'/authors/' + userBio.textId}>{authorContent}</Link>
  ) : (
      <>
      </>
  );
};
