import BadgeTested from '../../public/badge-tested.svg';
import BadgeVerified from '../../public/badge-verified.svg';
import BadgeVerifiedCheck from '../../public/badge-verified-check.svg';
import BadgeRated from '../../public/badge-quality.svg';
import BadgeLogoCircle from '../../public/badge-logo-circle.svg';
import BadgeLogoNew from '../../public/badge-logo-new.svg';

export const RatingBadge = ({ version }) => {
  return (
    <>
      {version === 'logo-new' && <BadgeLogoNew />}
      {version === 'logo-circle' && <BadgeLogoCircle />}
      {version === 'badge-rated' && <BadgeRated />}
      {version === 'badge-tested' && <BadgeTested />}
      {version === 'badge-verified' && <BadgeVerified />}
      {version === 'badge-verified-check' && <BadgeVerifiedCheck />}
    </>
  );
};
