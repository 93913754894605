export const httpGet = (url, skipEncode = false) => {
  if (skipEncode) {
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
  }
  return fetch(encodeURI(url), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
};
export const httpDelete = (url) => {
  return fetch(encodeURI(url), {
    method: 'DELETE',
  });
};
export const httpPost = (url, body) => {
  return fetch(encodeURI(url), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  });
};

export const fileUpload = (url, body) => {
  return fetch(encodeURI(url), {
    method: 'POST',
    body: body,
  });
};

export const httpPut = (url, body) => {
  return fetch(encodeURI(url), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  });
};
